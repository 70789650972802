import React from "react";
import useApiRequest from "../../../hooks/use-api-request.js";
import Loader from "../../../common/loader.jsx";
import ImageWithFallback from "../../../components/image-with-fallback.jsx";
import { Link } from "react-router-dom";

export default function EpisodeListTab({ guid }) {
  const { isLoading, response } = useApiRequest(`/api/series/${guid}/seasons/summary`, { cache: true });

  const seasons = response?.data;

  if (isLoading) {
    return (
      <div className="content-page__loader">
        <Loader />
      </div>
    );
  }

  return seasons?.length ? (
    <div>
      {seasons.map((season, index) => (
        <div className="gw" key={index}>
          <div className="g g--1-of-1">
            <div className="content-page__title content-page__title--separator">Season {season.season_number}</div>
          </div>
          {season.episodes?.length ? (
            season.episodes.map((episode, innerIndex) => (
              <div className="g g--tall g--1-of-4" key={innerIndex}>
                <div className="link-preview-box-container">
                  <Link
                    to={`/content/library/series/${episode.series_guid}/episode/${episode.episode_guid}`}
                    className="link-preview-box"
                  >
                    <span className="link-preview-box__image">
                      <ImageWithFallback url={episode.poster} />
                    </span>
                    <span className="link-preview-box__footer">
                      <span className="u-display--flex-apart u-display--flex-v-center u-fs--h6 u-width-100">
                        <span className="u-padding--small--inline-end">
                          S{season.season_number} E{episode.episode_number}
                        </span>
                        <span>{episode.episode_name}</span>
                      </span>
                    </span>
                  </Link>
                </div>
              </div>
            ))
          ) : (
            <div className="u-flex--center">No episodes found in season.</div>
          )}
        </div>
      ))}
    </div>
  ) : (
    <div className="u-flex-center">No seasons found</div>
  );
}
