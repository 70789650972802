import React from "react";
import TranscodingStatusIcon from "../../../components/transcoding-status-icon.jsx";
import { AssetPlayer } from "../../../../components/player/player.jsx";
import { getCuepointLocation } from "../../utils/create-ad-breaks.js";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import FeatureInformation from "./feature-information.jsx";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import { secondsToHourMinutesSeconds } from "../../../../common/duration-formatting.js";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import FaceIcon from "@mui/icons-material/Face";
import PaletteIcon from "@mui/icons-material/Palette";
import EpisodeInformation from "./episode-information.jsx";
import PromoInformation from "./promo-information.jsx";

export default function ProgramInformation({
  program,
  programInfo,
  transcodingTypes,
  addCuepoint,
  trimVideo,
  removeNearestCuepoint,
  onRemoveProgram,
  viewContentListProgram,
  type,
  ...props
}) {
  const timeInfo = {
    ad_time: programInfo?.__gstvMeta
      ? secondsToHourMinutesSeconds(
          programInfo.__gstvMeta.total_duration_seconds - programInfo.__gstvMeta.duration_seconds,
        )
      : program.cuepoints
        ? secondsToHourMinutesSeconds(program.cuepoints.length * 122)
        : 0,
    duration: programInfo?.__gstvMeta
      ? secondsToHourMinutesSeconds(programInfo?.__gstvMeta?.total_duration_seconds)
      : secondsToHourMinutesSeconds(program.trimmed_duration ?? program.duration),
  };
  return (
    <div className="program-information">
      <h3 className="program-information__heading">
        <span className="program-information__transcoding-status">
          {transcodingTypes.includes("hls") ? (
            <TranscodingStatusIcon status={program.content_video?.hls_status} popupPosition="right" type="hls" small />
          ) : null}
          {transcodingTypes.includes("dash") ? (
            <TranscodingStatusIcon
              status={program.content_video?.dash_status}
              popupPosition="right"
              type="dash"
              small
            />
          ) : null}
        </span>
        {programInfo.__gstvMeta ? (
          <button
            className="btn--text-primary program-information__remove"
            onClick={() => {
              onRemoveProgram(programInfo);
              viewContentListProgram(program);
            }}
          >
            <CancelRoundedIcon />
          </button>
        ) : null}
        <span className="program-information__heading__title">{program.formatted_name}</span>
      </h3>

      <div className="program-information__player">
        <AssetPlayer
          assetId={program.content_video?.asset_id}
          cuepoints={
            programInfo.cuepoints
              ? programInfo.cuepoints.map((adBreak, index) => ({
                  time: getCuepointLocation(adBreak),
                  label: `Cuepoint #${index + 1}`,
                  type: "cuepoint",
                }))
              : programInfo.__gstvMeta?.ad_breaks.map((adBreak, index) => ({
                  time: getCuepointLocation(adBreak),
                  label: `Cuepoint #${index + 1}`,
                  type: "cuepoint",
                }))
          }
          trimPoint={
            programInfo?.trimmed_duration
              ? programInfo.trimmed_duration
              : programInfo.__gstvMeta?.duration_seconds !== programInfo.__gstvMeta?.original_duration_seconds
                ? programInfo.__gstvMeta.duration_seconds
                : null
          }
          addCuepoint={addCuepoint}
          removeNearestCuepoint={removeNearestCuepoint}
          trimVideo={trimVideo}
          editControls={!!programInfo?.__gstvMeta}
        />
      </div>
      <div className="program-information__content-summary">
        <div className="program-information__content-summary__block" aria-label="Duration" data-balloon-pos="up">
          <div className="program-information__content-summary__icon">
            <AvTimerIcon />
          </div>
          <div className="program-information__content-summary__label">{timeInfo.duration}</div>
        </div>
        <div className="program-information__content-summary__block" aria-label="Total Ads" data-balloon-pos="up">
          <div className="program-information__content-summary__icon">
            <LiveTvIcon />
          </div>
          <div className="program-information__content-summary__label">{timeInfo.ad_time}</div>
        </div>
        <div className="program-information__content-summary__block" aria-label="Rating" data-balloon-pos="up">
          <div className="program-information__content-summary__icon">
            <FaceIcon />
          </div>
          <div className="program-information__content-summary__label">{program.rating?.us_tv?.rating ?? "N/A"}</div>
        </div>
        <div className="program-information__content-summary__block" aria-label="Genre" data-balloon-pos="up">
          <div className="program-information__content-summary__icon">
            <PaletteIcon />
          </div>
          <div className="program-information__content-summary__label">
            {program.genre?.imdb?.imdb_genre?.label ?? "N/A"}
          </div>
        </div>
      </div>
      {type === "feature" && <FeatureInformation feature={program} programInfo={programInfo} {...props} />}
      {type === "promo" && <PromoInformation promo={program} programInfo={programInfo} {...props} />}
      {type === "episode" && <EpisodeInformation episode={program} programInfo={programInfo} {...props} />}
    </div>
  );
}
