import React from "react";
import {
  getPanelGroupElement,
  getResizeHandleElement,
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from "react-resizable-panels";
import PanelResizeButton from "./panel-resize-button";

const ResizableLayout = React.forwardRef(({ children }, ref) => {
  const [isResizing, setResizing] = React.useState(false);
  const [disabledHandles, setDisabled] = React.useState({});
  const [collapsedPanels, setCollapsed] = React.useState({});
  const [minSizesInRatios, setMinSizes] = React.useState({});

  React.useImperativeHandle(ref, () => ({
    resetLayout: resetPanels,
  }));

  /// JUST USE A LIBRARY 4HEAD ///

  const leftPanelRef = React.useRef();
  const centerPanelRef = React.useRef();
  const rightPanelRef = React.useRef();
  const upperPanelRef = React.useRef();
  const lowerPanelRef = React.useRef();
  const rightUpperPanelRef = React.useRef();
  const rightMiddlePanelRef = React.useRef();
  const rightLowerPanelRef = React.useRef();

  const elementRefs = React.useRef();

  React.useEffect(() => {
    const leftVerticalResizeElement = getResizeHandleElement("left-resize");
    const rightVerticalResizeElement = getResizeHandleElement("right-resize");
    const bottomHorizontalResizeElement = getResizeHandleElement("bottom-resize");
    const rightLowerHorizontalResizeElement = getResizeHandleElement("right-lower-resize");
    const rightUpperHorizontalResizeElement = getResizeHandleElement("right-upper-resize");

    const mainPanelGroupElement = getPanelGroupElement("main-group");
    const innerPanelGroupElement = getPanelGroupElement("upper-group");
    const rightPanelGroupElement = getPanelGroupElement("right-group");

    elementRefs.current = {
      leftVerticalResizeElement,
      rightVerticalResizeElement,
      bottomHorizontalResizeElement,
      rightLowerHorizontalResizeElement,
      rightUpperHorizontalResizeElement,
      mainPanelGroupElement,
      innerPanelGroupElement,
      rightPanelGroupElement,
    };
  }, []);

  const pageLayout = React.useMemo(
    () => ({
      upper: {
        id: "upper",
        defaultSize: 85,
        minSize: 635,
        ref: upperPanelRef,
        sectionLabel: "Scheduler",
        className: "scheduler-layout__content",
        isCollapsed: collapsedPanels.upper,
      },
      lower: {
        id: "lower",
        defaultSize: 15,
        minSize: 135,
        ref: lowerPanelRef,
        children: children[5],
        sectionLabel: "Timeline",
        className: "scheduler-layout__bottom",
        isCollapsed: collapsedPanels.lower,
      },
      left: {
        id: "left",
        defaultSize: 20,
        minSize: 140,
        ref: leftPanelRef,
        children: children[0],
        sectionLabel: "Content List",
        className: "scheduler-layout__left",
        isCollapsed: collapsedPanels.left,
      },
      center: {
        id: "center",
        defaultSize: 55,
        minSize: 320,
        ref: centerPanelRef,
        children: children[1],
        sectionLabel: "Scheduler",
        className: "scheduler-layout__center",
        isCollapsed: collapsedPanels.center,
      },
      right: {
        id: "right",
        defaultSize: 25,
        minSize: 240,
        ref: rightPanelRef,
        sectionLabel: "Meta",
        className: "scheduler-layout__right",
        isCollapsed: collapsedPanels.right,
      },
      rightUpper: {
        id: "rightUpper",
        defaultSize: 30,
        minSize: 200,
        ref: rightUpperPanelRef,
        children: children[2],
        sectionLabel: "Playback",
        className: "scheduler-layout__player-container",
        isCollapsed: collapsedPanels.rightUpper,
      },
      rightMiddle: {
        id: "rightMiddle",
        defaultSize: 35,
        minSize: 90,
        ref: rightMiddlePanelRef,
        children: children[3],
        sectionLabel: "Schedule Information",
        isCollapsed: collapsedPanels.rightMiddle,
      },
      rightLower: {
        id: "rightLower",
        defaultSize: 35,
        minSize: 145,
        ref: rightLowerPanelRef,
        children: children[4],
        sectionLabel: "Program Information",
        isCollapsed: collapsedPanels.rightLower,
      },
    }),
    [
      children,
      collapsedPanels.center,
      collapsedPanels.left,
      collapsedPanels.lower,
      collapsedPanels.right,
      collapsedPanels.rightLower,
      collapsedPanels.rightMiddle,
      collapsedPanels.rightUpper,
      collapsedPanels.upper,
    ],
  );

  React.useLayoutEffect(() => {
    let mainGroupObserver = null;
    let innerGroupObserver = null;
    let rightGroupObserver = null;

    if (elementRefs.current) {
      const mainResizeHandle = elementRefs.current.bottomHorizontalResizeElement;
      const innerGroupResizeHandles = [
        elementRefs.current.leftVerticalResizeElement,
        elementRefs.current.rightVerticalResizeElement,
      ];
      const rightGroupResizeHandles = [
        elementRefs.current.rightUpperHorizontalResizeElement,
        elementRefs.current.rightLowerHorizontalResizeElement,
      ];

      mainGroupObserver = new ResizeObserver(() => {
        let mainGroupHeight = elementRefs.current.mainPanelGroupElement.getBoundingClientRect().height;
        mainGroupHeight -= mainResizeHandle.getBoundingClientRect().height;

        setMinSizes((prev) => ({
          ...prev,
          upper: (pageLayout.upper.minSize / mainGroupHeight) * 100,
          lower: (pageLayout.lower.minSize / mainGroupHeight) * 100,
        }));
      });

      innerGroupObserver = new ResizeObserver(() => {
        let innerGroupWidth = elementRefs.current.innerPanelGroupElement.getBoundingClientRect().width;

        innerGroupResizeHandles.forEach((resizeHandle) => {
          innerGroupWidth -= resizeHandle.getBoundingClientRect().width;
        });

        setMinSizes((prev) => ({
          ...prev,
          left: (pageLayout.left.minSize / innerGroupWidth) * 100,
          center: (pageLayout.center.minSize / innerGroupWidth) * 100,
          right: (pageLayout.right.minSize / innerGroupWidth) * 100,
        }));
      });

      rightGroupObserver = new ResizeObserver(() => {
        let rightPanelHeight = elementRefs.current.rightPanelGroupElement.getBoundingClientRect().height;

        rightGroupResizeHandles.forEach((resizeHandle) => {
          rightPanelHeight -= resizeHandle.getBoundingClientRect().height;
        });

        //  rounding up to try and get closer to expected value
        setMinSizes((prev) => ({
          ...prev,
          rightUpper: (pageLayout.rightUpper.minSize / rightPanelHeight) * 100,
          rightMiddle: (pageLayout.rightMiddle.minSize / rightPanelHeight) * 100,
          rightLower: (pageLayout.rightLower.minSize / rightPanelHeight) * 100,
        }));
      });

      mainGroupObserver.observe(elementRefs.current.mainPanelGroupElement);
      mainGroupObserver.observe(elementRefs.current.bottomHorizontalResizeElement);

      innerGroupObserver.observe(elementRefs.current.innerPanelGroupElement);
      innerGroupResizeHandles.forEach((resizeHandle) => {
        innerGroupObserver.observe(resizeHandle);
      });

      rightGroupObserver.observe(elementRefs.current.rightPanelGroupElement);
      rightGroupResizeHandles.forEach((resizeHandle) => {
        rightGroupObserver.observe(resizeHandle);
      });
    }

    return () => {
      if (mainGroupObserver) {
        mainGroupObserver.disconnect();
      }

      if (innerGroupObserver) {
        innerGroupObserver.disconnect();
      }

      if (rightGroupObserver) {
        rightGroupObserver.disconnect();
      }
    };
  }, [pageLayout]);

  function clickHandler(primary) {
    if (pageLayout[primary].isCollapsed) {
      expand(primary);
    } else {
      collapse(primary);
    }
  }

  function collapse(key) {
    switch (key) {
      // outer sections
      case pageLayout.upper.id:
        collapseAdjacentSection(pageLayout.upper, pageLayout.lower);
        break;
      case pageLayout.lower.id:
        collapseAdjacentSection(pageLayout.lower, pageLayout.upper);
        break;

      //  inner sections
      case pageLayout.left.id:
        collapseAdjacentSection(pageLayout.left, pageLayout.center, pageLayout.right);
        break;
      case pageLayout.center.id:
        collapseAdjacentSection(pageLayout.center, pageLayout.left, pageLayout.right);
        break;
      case pageLayout.right.id:
        collapseAdjacentSection(pageLayout.right, pageLayout.center, pageLayout.left);
        break;

      // inner right sections
      case pageLayout.rightUpper.id:
        collapseAdjacentSection(pageLayout.rightUpper, pageLayout.rightMiddle, pageLayout.rightLower);
        break;
      case pageLayout.rightMiddle.id:
        collapseAdjacentSection(pageLayout.rightMiddle, pageLayout.rightUpper, pageLayout.rightLower);
        break;
      case pageLayout.rightLower.id:
        collapseAdjacentSection(pageLayout.rightLower, pageLayout.rightMiddle, pageLayout.rightUpper);
        break;
    }

    updateCollapsed();
    updateHandleDisables();
  }

  function expand(key) {
    switch (key) {
      //  outer sections
      case pageLayout.upper.id:
        restoreSection(pageLayout.upper, pageLayout.lower);
        break;
      case pageLayout.lower.id:
        restoreSection(pageLayout.lower, pageLayout.upper);
        break;

      //  inner sections
      case pageLayout.left.id:
        restoreInnerSection(pageLayout.left, pageLayout.center, pageLayout.right);
        break;
      case pageLayout.center.id:
        restoreInnerSection(pageLayout.center, pageLayout.right, pageLayout.left);
        break;
      case pageLayout.right.id:
        restoreInnerSection(pageLayout.right, pageLayout.center, pageLayout.left);
        break;

      //  inner right sections
      case pageLayout.rightUpper.id:
        restoreInnerSection(pageLayout.rightUpper, pageLayout.rightMiddle, pageLayout.rightLower);
        break;
      case pageLayout.rightMiddle.id:
        restoreInnerSection(pageLayout.rightMiddle, pageLayout.rightLower, pageLayout.rightUpper);
        break;
      case pageLayout.rightLower.id:
        restoreInnerSection(pageLayout.rightLower, pageLayout.rightMiddle, pageLayout.rightUpper);
        break;
    }

    updateCollapsed();
    updateHandleDisables();
  }

  function collapseAdjacentSection(collapseSection, adjacentSection, otherSection = {}) {
    const collapseRef = collapseSection.ref.current;
    const adjacentRef = adjacentSection.ref.current;
    const otherRef = Object.keys(otherSection).length ? otherSection.ref.current : {};

    if ([collapseRef, adjacentRef, otherRef].every((ref) => !!ref)) {
      let space = collapseRef.getSize();
      let adjacentSize = adjacentRef.getSize();
      let otherSize = 0;
      if (Object.keys(otherSection).length) {
        otherSize = otherRef.getSize();
      }
      /**
       * Case #1: Two sections. One section is collapsed. Collapse one section, expand other section to 100
       * Case #2: Three sections. One section is collapsed. Collapse one section, expand other section to 100
       * Case #3: Three sections. No sections collapsed. Space is given to the adjacent section if not collapsed.
       */
      if (adjacentRef.isCollapsed() && !Object.keys(otherSection).length) {
        adjacentRef.resize(100);
      } else if (otherRef.isCollapsed()) {
        adjacentRef.resize(adjacentSize + space);
      } else {
        otherRef.resize(otherSize + space);
      }
      collapseRef.collapse();
    }
  }

  function restoreSection(restoreSection, adjacentSection) {
    const restoreRef = restoreSection.ref.current;
    const adjacentRef = adjacentSection.ref.current;

    if ([restoreRef, adjacentRef].every((ref) => !!ref)) {
      restoreRef.resize(minSizesInRatios[restoreSection.id]);
    }
  }

  function restoreInnerSection(restoreSection, adjacentSection, otherSection) {
    const restoreRef = restoreSection.ref.current;
    const adjacentRef = adjacentSection.ref.current;
    const otherRef = otherSection.ref.current;
    if ([restoreSection.ref.current, adjacentSection.ref.current, otherSection.ref.current].every((ref) => !!ref)) {
      // simplest case: either section is collapsed
      const offset = minSizesInRatios[restoreSection.id];

      //  shrink other section no further than its minSize
      if (adjacentRef.isCollapsed()) {
        otherRef.resize(Math.max(otherRef.getSize() - offset, minSizesInRatios[otherSection.id]));
      } else if (otherRef.isCollapsed()) {
        adjacentRef.resize(Math.max(adjacentRef.getSize() - offset, minSizesInRatios[adjacentSection.id]));
      } else {
        //  otherwise, get the bigger section and shrink it by till it reaches its minSize
        let largerSection = adjacentRef.getSize() >= otherRef.getSize() ? adjacentSection : otherSection;
        let smallerSection = adjacentRef.getSize() >= otherRef.getSize() ? otherSection : adjacentSection;
        let smallerSize = smallerSection.ref.current.getSize();

        let largerSize = Math.max(minSizesInRatios[largerSection.id], largerSection.ref.current.getSize() - offset);
        let totalSize = largerSize + offset + smallerSection.ref.current.getSize();
        if (totalSize > 100) {
          smallerSize = totalSize - 100;
        }

        smallerSection.ref.current.resize(smallerSize);
        largerSection.ref.current.resize(largerSize);
      }

      restoreRef.resize(offset);
    }
  }

  // set initial state after page has loaded
  React.useLayoutEffect(() => {
    setDisabled({
      lower: lowerPanelRef.current.isCollapsed() || upperPanelRef.current.isCollapsed(),
      left: leftPanelRef.current.isCollapsed() || centerPanelRef.current.isCollapsed(),
      right: rightPanelRef.current.isCollapsed() || centerPanelRef.current.isCollapsed(),
      rightUpper: rightUpperPanelRef.current.isCollapsed(),
      rightLower: rightMiddlePanelRef.current.isCollapsed() || rightMiddlePanelRef.current.isCollapsed(),
    });

    setCollapsed({
      upper: upperPanelRef.current.isCollapsed(),
      lower: lowerPanelRef.current.isCollapsed(),

      left: leftPanelRef.current.isCollapsed(),
      center: centerPanelRef.current.isCollapsed(),
      right: rightPanelRef.current.isCollapsed(),

      rightUpper: rightUpperPanelRef.current.isCollapsed(),
      rightMiddle: rightMiddlePanelRef.current.isCollapsed(),
      rightLower: rightLowerPanelRef.current.isCollapsed(),
    });
  }, []);

  function resetPanels() {
    const mainRefs = [upperPanelRef.current, lowerPanelRef.current];
    const innerRefs = [leftPanelRef.current, centerPanelRef.current, rightPanelRef.current];
    const innerRightRefs = [rightUpperPanelRef.current, rightMiddlePanelRef.current, rightLowerPanelRef.current];

    // in case it matters, start with innermost panels and work outwards
    if (innerRightRefs.every((ref) => !!ref)) {
      innerRightRefs[0].resize(Math.max(pageLayout.rightUpper.defaultSize, minSizesInRatios[pageLayout.rightUpper.id]));
      innerRightRefs[1].resize(
        Math.max(pageLayout.rightMiddle.defaultSize, minSizesInRatios[pageLayout.rightMiddle.id]),
      );
      innerRightRefs[2].resize(Math.max(pageLayout.rightLower.defaultSize, minSizesInRatios[pageLayout.rightLower.id]));
    }

    if (innerRefs.every((ref) => !!ref)) {
      const innerSections = [pageLayout.left, pageLayout.center, pageLayout.right];
      const totalRatioInner = innerSections.reduce(
        (accumulator, currentValue) => accumulator + minSizesInRatios[currentValue.id],
        0,
      );

      innerSections.forEach((section) =>
        section.ref.current.resize(totalRatioInner >= 90 ? minSizesInRatios[section.id] : section.defaultSize),
      );
    }

    if (mainRefs.every((ref) => !!ref)) {
      mainRefs[0].resize(Math.max(pageLayout.upper.defaultSize, minSizesInRatios[pageLayout.upper.id]));
      mainRefs[1].resize(Math.max(pageLayout.lower.defaultSize, minSizesInRatios[pageLayout.lower.id]));
    }

    setResizing(false);
    setDisabled({
      lower: false,
      left: false,
      right: false,
      rightUpper: false,
      rightLower: false,
    });
    setCollapsed({
      upper: false,
      lower: false,

      left: false,
      center: false,
      right: false,

      rightUpper: false,
      rightMiddle: false,
      rightLower: false,
    });
  }

  function updateCollapsed() {
    setCollapsed({
      upper: upperPanelRef.current.isCollapsed(),
      lower: lowerPanelRef.current.isCollapsed(),

      left: leftPanelRef.current.isCollapsed(),
      center: centerPanelRef.current.isCollapsed(),
      right: rightPanelRef.current.isCollapsed(),

      rightUpper: rightUpperPanelRef.current.isCollapsed(),
      rightMiddle: rightMiddlePanelRef.current.isCollapsed(),
      rightLower: rightLowerPanelRef.current.isCollapsed(),
    });
  }

  function updateHandleDisables() {
    const upper = upperPanelRef.current.isCollapsed();
    const lower = lowerPanelRef.current.isCollapsed();

    const left = leftPanelRef.current.isCollapsed();
    const center = centerPanelRef.current.isCollapsed();
    const right = rightPanelRef.current.isCollapsed();

    const rightUpper = rightUpperPanelRef.current.isCollapsed();
    const rightMiddle = rightMiddlePanelRef.current.isCollapsed();
    const rightLower = rightLowerPanelRef.current.isCollapsed();

    //  main panel set
    setDisabled((prev) => ({
      ...prev,
      lower: !upper && !lower ? false : true,
    }));

    //  inner panel set
    if (!left && !center && !right) {
      // none collapsed
      setDisabled((prev) => ({
        ...prev,
        left: false,
        right: false,
      }));
    } else if (center || (left && right)) {
      // if center or both edge panels collapsed
      setDisabled((prev) => ({
        ...prev,
        left: true,
        right: true,
      }));
    } else {
      setDisabled((prev) => ({
        ...prev,
        left: left ? true : false,
        right: right ? true : false,
      }));
    }

    // inner right handles
    setDisabled((prev) => ({
      ...prev,
      rightUpper: rightUpper || rightMiddle,
      rightLower: rightLower || (rightMiddle && rightUpper),
    }));
  }

  return (
    <PanelGroup direction="vertical" autoSaveId="main-group" id="main-group">
      <Panel
        id={pageLayout.upper.id}
        defaultSize={pageLayout.upper.defaultSize}
        minSize={minSizesInRatios.upper}
        ref={pageLayout.upper.ref}
        collapsible={upperPanelRef.current?.isCollapsed() || !isResizing}
      >
        <PanelGroup direction="horizontal" autoSaveId="upper-group" id="upper-group">
          <Panel
            id={pageLayout.left.id}
            defaultSize={pageLayout.left.defaultSize}
            minSize={minSizesInRatios.left}
            ref={pageLayout.left.ref}
            className={pageLayout.left.className}
            collapsible={leftPanelRef.current?.isCollapsed() || !isResizing}
          >
            {children[0]}
          </Panel>
          <PanelResizeHandle
            id="left-resize"
            className="scheduler-layout__resize-handle"
            onDragging={(isDragging) => setResizing(isDragging)}
            disabled={disabledHandles.left}
            hitAreaMargins={{ coarse: 0, fine: 0 }}
          >
            <PanelResizeButton
              sectionBefore={{
                id: pageLayout.left.id,
                label: pageLayout.left.sectionLabel,
                isCollapsed: collapsedPanels.left,
              }}
              sectionAfter={{
                id: pageLayout.center.id,
                label: pageLayout.center.sectionLabel,
                isCollapsed: collapsedPanels.center,
              }}
              altId={pageLayout.right.id}
              clickHandler={clickHandler}
              mode={
                !collapsedPanels.left && collapsedPanels.center ? (!collapsedPanels.right ? "alt" : "after") : "before"
              }
            />
          </PanelResizeHandle>
          <Panel
            id={pageLayout.center.id}
            defaultSize={pageLayout.center.defaultSize}
            minSize={minSizesInRatios.center}
            ref={pageLayout.center.ref}
            className={pageLayout.center.className}
            collapsible={centerPanelRef.current?.isCollapsed() || !isResizing}
          >
            {children[1]}
          </Panel>
          <PanelResizeHandle
            id="right-resize"
            className={`scheduler-layout__resize-handle ${collapsedPanels.center && !collapsedPanels.right && !collapsedPanels.left ? "scheduler-layout__resize-handle--hidden" : ""}`}
            onDragging={(isDragging) => setResizing(isDragging)}
            disabled={disabledHandles.right}
            hitAreaMargins={{ coarse: 0, fine: 0 }}
          >
            <PanelResizeButton
              sectionBefore={{
                id: pageLayout.center.id,
                label: pageLayout.center.sectionLabel,
                isCollapsed: collapsedPanels.center,
              }}
              sectionAfter={{
                id: pageLayout.right.id,
                label: pageLayout.right.sectionLabel,
                isCollapsed: collapsedPanels.right,
              }}
              clickHandler={clickHandler}
              mode={collapsedPanels.center && !collapsedPanels.right && collapsedPanels.left ? "before" : "after"}
            />
          </PanelResizeHandle>
          <Panel
            id={pageLayout.right.id}
            defaultSize={pageLayout.right.defaultSize}
            minSize={minSizesInRatios.right}
            ref={pageLayout.right.ref}
            className={pageLayout.right.className}
            collapsible={rightPanelRef.current?.isCollapsed() || !isResizing}
          >
            <PanelGroup direction="vertical" autoSaveId="right-group" id="right-group">
              <Panel
                id={pageLayout.rightUpper.id ?? ""}
                className={pageLayout.rightUpper.className}
                defaultSize={pageLayout.rightUpper.defaultSize}
                minSize={minSizesInRatios.rightUpper}
                ref={pageLayout.rightUpper.ref}
                collapsible={rightUpperPanelRef.current?.isCollapsed() || !isResizing}
              >
                {pageLayout.rightUpper.children}
              </Panel>
              <PanelResizeHandle
                id="right-upper-resize"
                className={`scheduler-layout__resize-handle scheduler-layout__resize-handle--horizontal`}
                onDragging={(isDragging) => setResizing(isDragging)}
                disabled={disabledHandles.rightUpper}
                hitAreaMargins={{ coarse: 0, fine: 0 }}
              >
                <PanelResizeButton
                  sectionBefore={{
                    id: pageLayout.rightUpper.id,
                    label: pageLayout.rightUpper.sectionLabel,
                    isCollapsed: collapsedPanels.rightUpper,
                  }}
                  sectionAfter={{
                    id: pageLayout.rightMiddle.id,
                    label: pageLayout.rightMiddle.sectionLabel,
                    isCollapsed: collapsedPanels.rightMiddle,
                  }}
                  altId={pageLayout.rightLower.id}
                  clickHandler={clickHandler}
                  mode={
                    !collapsedPanels.rightUpper && collapsedPanels.rightMiddle
                      ? !collapsedPanels.rightLower
                        ? "alt"
                        : "after"
                      : "before"
                  }
                />
              </PanelResizeHandle>
              <Panel
                id={pageLayout.rightMiddle.id}
                defaultSize={pageLayout.rightMiddle.defaultSize}
                minSize={minSizesInRatios.rightMiddle}
                ref={pageLayout.rightMiddle.ref}
                collapsible={rightMiddlePanelRef.current?.isCollapsed() || !isResizing}
              >
                {pageLayout.rightMiddle.children}
              </Panel>
              <PanelResizeHandle
                id="right-lower-resize"
                className={`scheduler-layout__resize-handle scheduler-layout__resize-handle--horizontal ${collapsedPanels.rightMiddle && !collapsedPanels.rightLower && !collapsedPanels.rightUpper ? "scheduler-layout__resize-handle--hidden" : ""}`}
                onDragging={(isDragging) => setResizing(isDragging)}
                disabled={disabledHandles.rightLower}
                hitAreaMargins={{ coarse: 0, fine: 0 }}
              >
                <PanelResizeButton
                  sectionBefore={{
                    id: pageLayout.rightMiddle.id,
                    label: pageLayout.rightMiddle.sectionLabel,
                    isCollapsed: collapsedPanels.rightMiddle,
                  }}
                  sectionAfter={{
                    id: pageLayout.rightLower.id,
                    label: pageLayout.rightLower.sectionLabel,
                    isCollapsed: collapsedPanels.rightLower,
                  }}
                  clickHandler={clickHandler}
                  mode={
                    collapsedPanels.rightMiddle && !collapsedPanels.rightLower && collapsedPanels.rightUpper
                      ? "before"
                      : "after"
                  }
                />
              </PanelResizeHandle>
              <Panel
                id={pageLayout.rightLower.id}
                defaultSize={pageLayout.rightLower.defaultSize}
                minSize={minSizesInRatios.rightLower}
                ref={pageLayout.rightLower.ref}
                collapsible={rightLowerPanelRef.current?.isCollapsed() || !isResizing}
              >
                {pageLayout.rightLower.children}
              </Panel>
            </PanelGroup>
          </Panel>
        </PanelGroup>
      </Panel>
      <PanelResizeHandle
        id="bottom-resize"
        className={`scheduler-layout__resize-handle scheduler-layout__resize-handle--horizontal ${collapsedPanels.lower ? "scheduler-layout__resize-handle--collapsed" : ""}`}
        onDragging={(isDragging) => setResizing(isDragging)}
        disabled={disabledHandles.lower}
        hitAreaMargins={{ coarse: 0, fine: 0 }}
      >
        <PanelResizeButton
          sectionBefore={{
            id: pageLayout.upper.id,
            label: pageLayout.upper.sectionLabel,
            isCollapsed: collapsedPanels.upper,
          }}
          sectionAfter={{
            id: pageLayout.lower.id,
            label: pageLayout.lower.sectionLabel,
            isCollapsed: collapsedPanels.lower,
          }}
          clickHandler={clickHandler}
          mode={collapsedPanels.upper ? "before" : "after"}
        />
      </PanelResizeHandle>
      <Panel
        id={pageLayout.lower.id}
        defaultSize={pageLayout.lower.defaultSize}
        minSize={minSizesInRatios.lower}
        ref={pageLayout.lower.ref}
        className={pageLayout.lower.className}
        collapsible={lowerPanelRef.current?.isCollapsed() || !isResizing}
      >
        {children[5]}
      </Panel>
    </PanelGroup>
  );
});
ResizableLayout.displayName = "ResizableLayout";

export default ResizableLayout;
