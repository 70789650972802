import React from "react";

function PanelResizeButton({ mode, clickHandler, sectionBefore, sectionAfter, altId }) {
  return (
    <React.Fragment>
      {!sectionBefore?.isCollapsed ? (
        <button
          className="scheduler-layout__resize-handle__button"
          onDoubleClick={() => clickHandler(sectionBefore.id, sectionAfter.id)}
        ></button>
      ) : null}

      {sectionBefore?.isCollapsed && mode === "before" ? (
        <button
          className="scheduler-layout__resize-handle__collapsed-button"
          onDoubleClick={() => {
            clickHandler(sectionBefore.id, sectionAfter.id);
          }}
        >
          {sectionBefore.label}
        </button>
      ) : null}

      {mode === "alt" || (sectionAfter?.isCollapsed && mode === "after") ? (
        <button
          className="scheduler-layout__resize-handle__collapsed-button"
          onDoubleClick={() => {
            clickHandler(sectionAfter.id, sectionBefore.id);
          }}
        >
          {sectionAfter?.label}
        </button>
      ) : null}

      {mode === "alt" || !sectionAfter?.isCollapsed ? (
        <button
          className="scheduler-layout__resize-handle__button"
          onDoubleClick={() => {
            clickHandler(mode !== "alt" ? sectionAfter.id : altId, sectionBefore.id);
          }}
        ></button>
      ) : null}
    </React.Fragment>
  );
}

export default PanelResizeButton;
