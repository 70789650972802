import React from "react";
import { createPortal } from "react-dom";
import ReactModal from "react-modal";
import DatePicker from "react-datepicker";
import format from "date-fns/format";
import fetcher from "../../../../common/fetcher.js";
import { BASE_URL } from "../../../../requests/api-requests.js";
import { toast } from "react-toastify";
import { addDays, parseISO, setHours, setMinutes } from "date-fns";
import axios from "../../../../requests/axios.js";
import Loader from "../../../../common/loader.jsx";
import "react-datepicker/dist/react-datepicker.css";
import programTimeCorrection from "../../../../functions/program-time-correction.js";
import dateIsBetween from "../../../../common/date-is-between.js";
import { formatInTimeZone } from "date-fns-tz";

function CopyPlanDialog({ planDate, isOpen, onClose, channelId, updateActiveDate }) {
  const [sourceDate, setSourceDate] = React.useState(new Date());
  const [sourcePlan, setSourcePlan] = React.useState({});
  const [targetDate, setTargetDate] = React.useState(new Date());
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  React.useEffect(() => {
    if (isOpen) {
      // prefill source date to current plan
      setSourceDate(planDate);

      // prefill target date to day after current plan
      const newTargetDate = addDays(new Date(planDate.getTime()), 1);
      setTargetDate(newTargetDate);

      // set duration of initial source plan
      getPlan(channelId, planDate).then((plan) => setSourcePlan(plan));
    }
  }, [isOpen, channelId, planDate]);

  async function getPlan(channel_id, date) {
    const plan = await fetcher(`${BASE_URL}/api/channels/${channel_id}/plans?plan_date=${format(date, "y-MM-dd")}`);
    if (!plan) {
      return null;
    }
    return plan;
  }

  function validatePrograms() {
    return sourcePlan.programs.filter((program) =>
      sourcePlan.plan_breaks.some((pBreak) =>
        dateIsBetween(
          setHours(
            setMinutes(
              parseISO(formatInTimeZone(program.program_start, "UTC", "Y-MM-dd HH:mm:ss")),
              pBreak.end.split(":")[1],
            ),
            pBreak.end.split(":")[0],
          ),
          parseISO(formatInTimeZone(program.program_start, "UTC", "Y-MM-dd HH:mm:ss")),
          parseISO(formatInTimeZone(program.program_end, "UTC", "Y-MM-dd HH:mm:ss")),
          "(]",
        ),
      ),
    );
  }

  function onSubmit() {
    setIsLoading(true);

    if (validatePrograms().length) {
      toast.error("Cannot copy. There are errors in the selected schedule.");
      setIsLoading(false);
      return;
    }

    // The update api call expects the plan_id for the target date, so first get that
    const payload = {};

    // copy the plan breaks
    payload.plan_breaks = sourcePlan.plan_breaks;

    // copy the programs using the same time but the target date
    payload.programs = sourcePlan.programs.map((program) => {
      const { program_start, program_end } = programTimeCorrection(
        format(targetDate, "y-MM-dd"),
        new Date(program.program_start),
        new Date(program.program_end),
      );

      // no need to call beforeISOString here because we are working with the api data directly (which comes back as UTC anyway)
      return {
        ...program,
        program_id: null,
        program_start: program_start,
        program_end: program_end,
      };
    });

    axios
      .put(`${BASE_URL}/api/channels/${channelId}/plans/${format(targetDate, "y-MM-dd")}`, payload)
      .then(() => {
        onClose();
        toast.success("Plan copied successfully");
        updateActiveDate(targetDate);
      })
      .catch((e) => {
        console.error(e);
        setErrorMessage("There was an error copying your plan, please contact support.");
      })
      .finally(() => setIsLoading(false));
  }

  function onSourceDateChange(date) {
    setSourceDate(new Date(date));
    getPlan(channelId, date).then((plan) => setSourcePlan(plan));
  }

  function onTargetDateChange(date) {
    setTargetDate(new Date(date));
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-copy">
        <div className="v-react-modal__heading">Copy Plan</div>
        <div className="v-react-modal__body modal-copy__body">
          <div className="modal-copy__dropdown-group">
            <div className="modal-copy__body__text">Copy from:</div>
            <div className="modal-copy__dropdown">
              <DatePicker
                selected={new Date(sourceDate)}
                onChange={(date) => onSourceDateChange(date)}
                popperContainer={({ children }) => createPortal(children, document.body)}
                dateFormat="yyyy-MM-dd"
              />
            </div>
          </div>
          <div className="modal-copy__dropdown-group">
            <div className="modal-copy__body__text">Paste to:</div>
            <div className="modal-copy__dropdown">
              <DatePicker
                selected={new Date(targetDate)}
                onChange={(date) => onTargetDateChange(date)}
                minDate={addDays(new Date(), 1)}
                popperContainer={({ children }) => createPortal(children, document.body)}
                dateFormat="yyyy-MM-dd"
              />
            </div>
          </div>
          <p className="modal-copy__body__subtext">
            {`Plan duration: ${sourcePlan.duration ? (sourcePlan.duration / 3600).toFixed(2) : "0.00"}hrs`}
          </p>
          <p className="modal-copy__body__subtext u-fc--warning">
            This action will overwrite any existing programs on the target
          </p>
          {errorMessage ? <p className="modal-copy__body__subtext u-fc--warning">{errorMessage}</p> : null}
        </div>
        <div className="v-react-modal__footer" style={{ marginTop: "4px" }}>
          {isLoading ? (
            <Loader width={45} height={45} />
          ) : (
            [
              <button className="btn btn--inverse-primary" onClick={onClose} key="copy-plan-dialog-cancel">
                Cancel
              </button>,
              <button className="btn btn--primary" onClick={onSubmit} key="copy-plan-dialog-submit">
                Copy Schedule
              </button>,
            ]
          )}
        </div>
      </div>
    </ReactModal>
  );
}

export default CopyPlanDialog;
