import React from "react";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-material.css";
import { AgGridReact } from "ag-grid-react";
import useApiRequest from "../hooks/use-api-request.js";
import axios from "../requests/axios.js";
import CustomAgGridSidebar from "../components/ag-grid/custom-ag-grid-sidebar.jsx";
import CustomGenreComponent from "../components/ag-grid/custom-genre-component.jsx";
import SidebarElements from "../components/ag-grid/sidebar-elements/sidebar-elements.jsx"; // Optional Theme applied to the grid
import CustomImagesComponent from "../components/ag-grid/custom-images-component.jsx";
import getAllQcRows from "./quality-control/get-all-qc-rows.js";
import { toast } from "react-toastify";
import { AppHeading } from "../layout/parts/app-heading.jsx";
import AppBody from "../layout/parts/app-body.jsx";

const LinkComponent = (props) => {
  return (
    <a href={props.value} target="_blank" rel="noreferrer">
      {props.column.userProvidedColDef.headerName}
    </a>
  );
};

export default function QualityControlPage() {
  const {
    response: ratings,
    isLoading: ratingsLoading,
    error: ratingsError,
  } = useApiRequest("/api/ratings", { cache: true });
  const {
    response: genres,
    isLoading: genresLoading,
    error: genresError,
  } = useApiRequest("/api/genres", { cache: true });
  const [colDefs, setColDefs] = React.useState();
  const [showSidebar, setShowSidebar] = React.useState({ show: false, element: null, elementTitle: null, props: {} });
  const gridRef = React.useRef();

  const isReady = !genresError && !ratingsError && !genresLoading && !ratingsLoading;

  const onChange = React.useCallback(
    (event) => {
      const contentType = event.data.content_type;
      const contentId = event.data.content_id;
      let field = event.colDef.field;
      let value = event.newValue;

      // for genres and subgenres, we need to send the value instead of the label
      if (field === "genre.imdb.imdb_genre.label" && genres.data.imdb) {
        field = "genre.imdb.imdb_genre.value";
        const matchingGenre = genres.data.imdb.find((g) => g.label === value);
        if (!matchingGenre) {
          console.error(`No matching genre found for ${value}`);
          return false;
        }

        value = matchingGenre.value;
      } else if (field === "genre.imdb.imdb_subgenre.label" && genres.data.imdb) {
        field = "genre.imdb.imdb_subgenre.subgenre";
        const matchingGenre = genres.data.imdb.find((g) => g.label === event.data.genre.imdb.imdb_genre.label);

        if (!matchingGenre) {
          console.error(`No matching genre found for ${event.data.genre.imdb.imdb_genre.label}`);
          return false;
        }

        const matchingSubgenre = matchingGenre.subgenres.find((sg) => sg.label === value);
        if (!matchingSubgenre) {
          console.error(
            `No matching subgenre found for genre ${event.data.genre.imdb.imdb_genre.label} and subgenre ${value}`,
          );
          return false;
        }
        value = matchingSubgenre.subgenre;
      }

      axios
        .post(`/api/quality-control/${contentType}/${contentId}`, {
          field,
          value,
        })
        .then(() => {})
        .catch((err) => {
          // @todo reset to prev value
          console.error(err);
        });
    },
    [genres.data?.imdb],
  );

  function onSelectionChange() {
    // @todo
  }

  // Primary Genre callbacks
  const getPrimaryImdbGenre = React.useCallback((source) => {
    if (!source.data.genre) {
      return "";
    }

    const main = source.data.genre;
    if (!main.imdb || !main.imdb.imdb_genre) {
      return "";
    }

    if (!main.imdb.imdb_genre) {
      return "";
    }
    return main.imdb.imdb_genre.label ?? "";
  }, []);

  const setPrimaryImdbGenre = React.useCallback(
    (source) => {
      const { newValue } = source;
      const genreValue = genres.data.imdb.find((g) => g.label === newValue);
      if (!genreValue) {
        return false;
      }

      if (!source.data.genre) {
        source.data.genre = {};
      }

      source.data.genre = {
        ...source.data.genre,
        imdb: { imdb_genre: { value: genreValue.value, label: genreValue.label } },
      };
      return true;
    },
    [genres],
  );

  const getPrimaryImdbSubgenre = React.useCallback((source) => {
    if (!source.data.genre) {
      return "";
    }

    const main = source.data.genre;
    if (!main.imdb) {
      return "";
    }

    if (!main.imdb.imdb_subgenre) {
      return "";
    }

    return main.imdb.imdb_subgenre.label;
  }, []);

  const setPrimaryImdbSubgenre = React.useCallback(
    (source) => {
      const { newValue } = source;
      const mainGenre = source.data.genre.imdb?.imdb_genre;
      if (!mainGenre) {
        return false;
      }

      const genreValue = genres.data.imdb.find((g) => g.value === mainGenre.value);
      if (!genreValue) {
        return false;
      }

      const subgenreValue = genreValue.subgenres.find((sg) => sg.label === newValue);
      if (!subgenreValue) {
        return false;
      }

      source.data.genre.imdb.imdb_subgenre = {
        value: subgenreValue.value,
        label: subgenreValue.label,
      };
      return true;
    },
    [genres],
  );

  // Freeview
  const getPrimaryFreeviewGenre = React.useCallback((source) => {
    if (!source.data.genre) {
      return "";
    }

    const main = source.data.genre;
    if (!main.freeview) {
      return "";
    }

    return main.freeview.freeview_genre?.label ?? "";
  }, []);

  const setPrimaryFreeviewGenre = React.useCallback(
    (source) => {
      if (!genres.data.freeview) {
        return false;
      }

      const { newValue } = source;
      const genreValue = genres.data.freeview.find((g) => g.label === newValue);
      if (!genreValue) {
        return false;
      }

      if (!source.data.genre) {
        source.data.genre = {};
      }

      source.data.genre = {
        ...source.data.genre,
        freeview: { freeview_genre: { value: genreValue.value, label: genreValue.label } },
      };
      return true;
    },
    [genres.data?.freeview],
  );

  const getGenreLabels = React.useCallback((source) => {
    return source.data.genres
      .map((g) => {
        let result = "";
        if (g.imdb_genre) {
          result += g.imdb_genre.label;
        }

        return result;
      })
      .filter((i) => !!i);
  }, []);

  // On load, fetch all data
  React.useEffect(() => {
    async function populateData() {
      const generator = getAllQcRows();
      while (generator.hasNext()) {
        try {
          const rows = await generator.next();
          if (rows) {
            gridRef.current.api.applyTransaction({
              add: rows,
            });
          }
        } catch {
          toast.error("Could not fetch data, please contact support.");
          break;
        }
      }
    }

    populateData().then().catch();
  }, []);

  React.useEffect(() => {
    if (isReady) {
      setColDefs([
        {
          field: "provider_name",
          headerName: "Provider",
          width: 180,
          editable: false,
        },
        {
          field: "content_type",
          headerName: "Content Type",
          width: 180,
          editable: false,
        },
        {
          field: "content_name",
          headerName: "Content Name",
          width: 420,
          editable: false,
        },
        {
          field: "created_at",
          headerName: "Created At",
          width: 170,
          editable: false,
          type: "date",
        },
        {
          headerName: "IMDb Search",
          valueGetter: (source) => `https://www.imdb.com/find/?q=${source.data.content_name}`,
          cellRenderer: LinkComponent,
        },
        {
          field: "series_name",
          headerName: "Series Name",
          filter: true,
          width: 360,
          editable: false,
        },
        {
          field: "season_number",
          headerName: "Season Number",
          width: 160,
          editable: false,
        },
        {
          field: "episode_number",
          headerName: "Episode Number",
          width: 160,
          editable: false,
        },
        {
          field: "episode_name",
          headerName: "Episode Name",
          width: 360,
          editable: false,
        },
        {
          field: "long_summary",
          headerName: "Summary",
          cellEditor: "agLargeTextCellEditor",
          cellEditorPopup: true,
          width: 360,
          editable: true,
          cellEditorParams: {
            maxLength: 999999999,
          },
        },
        {
          field: "short_summary",
          headerName: "Short summary",
          cellEditor: "agLargeTextCellEditor",
          cellEditorPopup: true,
          width: 360,
          editable: true,
        },
        {
          field: "bbfc_rating",
          headerName: "BBFC Rating",
          width: 140,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: {
            values: ratings && ratings.data ? ratings.data.bbfc.map((rating) => rating.label) : ["nothing", "here"],
          },
        },
        {
          field: "us_tv_rating",
          headerName: "US-TV Rating",
          width: 140,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: {
            values: ratings && ratings.data ? ratings.data.us_tv.map((rating) => rating.label) : ["nothing", "here"],
          },
        },
        {
          field: "mpaa_rating",
          headerName: "MPAA Rating",
          width: 140,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: {
            values: ratings && ratings.data ? ratings.data.mpaa.map((rating) => rating.label) : ["nothing", "here"],
          },
        },
        {
          headerName: "Genres",
          width: 240,
          editable: false,
          valueGetter: (source) => getGenreLabels(source),
          filterParams: {
            textFormatter: (value) => {
              if (typeof value === "object") {
                value = value.join(", ");
              }
              return value.toLowerCase();
            },
          },
          cellRenderer: CustomGenreComponent,
          cellRendererParams: {
            onInteract: (props) =>
              setShowSidebar({
                show: true,
                element: "sidebar-content-genres",
                elementTitle: "Genres",
                props,
              }),
          },
        },
        {
          headerName: "IMDb Genre",
          field: "genre.imdb.imdb_genre",
          width: 200,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: (params) => {
            if (params.data.genres && params.data.genres.length > 0) {
              return { values: params.data.genres.filter((g) => !!g.imdb_genre).map((g) => g.imdb_genre.label) };
            }

            return { values: genres.data.imdb.filter((g) => !!g.imdb_genre?.label).map((g) => g.imdb_genre.label) };
          },
          valueGetter: getPrimaryImdbGenre,
          valueSetter: setPrimaryImdbGenre,
        },
        {
          headerName: "IMDb Subgenre",
          field: "genre.imdb.imdb_subgenre",
          valueGetter: getPrimaryImdbSubgenre,
          valueSetter: setPrimaryImdbSubgenre,
          width: 200,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: (params) => {
            if (!(params.data.genre && params.data.genre.imdb)) {
              return { values: [] };
            }

            const main = params.data.genre.imdb.imdb_genre.value;
            if (!main) {
              return { values: [] };
            }

            const options = genres.data.imdb.find((g) => g.value === main);
            if (!options) {
              return { values: [] };
            }

            return { values: options.subgenres.map((g) => g.label) };
          },
        },
        {
          headerName: "Freeview Genre",
          field: "genre.freeview.freeview_genre",
          width: 200,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: () => {
            return { values: genres.data?.freeview.map((g) => g.label) };
          },
          valueGetter: getPrimaryFreeviewGenre,
          valueSetter: setPrimaryFreeviewGenre,
        },
        {
          headerName: "Images",
          width: 160,
          editable: false,
          cellRenderer: CustomImagesComponent,
          cellRendererParams: {
            onInteract: (props) =>
              setShowSidebar({
                show: true,
                element: "sidebar-content-images",
                elementTitle: "Images",
                props,
              }),
          },
        },
      ]);
    }
  }, [
    ratingsLoading,
    genresLoading,
    genres.data?.freeview,
    genres.data?.imdb,
    getPrimaryFreeviewGenre,
    getPrimaryImdbGenre,
    getPrimaryImdbSubgenre,
    getGenreLabels,
    isReady,
    ratings,
    setPrimaryFreeviewGenre,
    setPrimaryImdbGenre,
    setPrimaryImdbSubgenre,
  ]);

  // Apply settings across all columns
  const defaultColDef = React.useMemo(() => {
    return {
      filter: true,
      editable: true,
    };
  }, []);

  const onSet = React.useCallback(
    (id, key, next) => {
      if (id === null || id === undefined || !key) {
        return false;
      }

      const node = gridRef.current.api.getRowNode(id);

      let nextData = null;
      switch (key) {
        case "genres":
          nextData = { ...node.data, genres: next };
          node.setData(nextData);
          onChange({
            data: node.data,
            colDef: { field: "genres" },
            newValue: next,
          });
          return true;
        default:
          return false;
      }
    },
    [onChange],
  );
  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={[
          {
            title: "Quality Control",
            link: "/content/quality-control",
          },
        ]}
      />
      <AppBody>
        <div className="ag-theme-material gstv-ag-grid">
          <AgGridReact
            rowData={undefined}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            pagination={true}
            rowSelection="multiple"
            onSelectionChanged={onSelectionChange}
            onCellValueChanged={onChange}
            ref={gridRef}
          />
          <CustomAgGridSidebar
            isExpanded={showSidebar.show}
            onClose={() => setShowSidebar((prev) => ({ ...prev, show: false }))}
          >
            <SidebarElements
              element={showSidebar.element}
              elementTitle={showSidebar.elementTitle}
              genreList={genres.data}
              ratingList={ratings.data}
              isVisible={showSidebar.show}
              onClose={() => setShowSidebar((prev) => ({ ...prev, show: false }))}
              onSet={onSet}
              {...showSidebar.props}
            />
          </CustomAgGridSidebar>
        </div>
      </AppBody>
    </React.Fragment>
  );
}
