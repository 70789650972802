import React from "react";
import { getTypeForContent } from "../utils/accessors.js";
import Loader from "../../../common/loader.jsx";
import { getContentByType } from "../../../requests/api-requests.js";
import { useSchedulerContext } from "../../../providers/scheduler-context.jsx";
import ProgramInformation from "./program-information/program-information.jsx";

const cache = {};

function MissingProgram() {
  return (
    <div className="epg-program-overview">
      <div className="missing-content">
        <p className="missing-content__text">Select a program to see more information</p>
      </div>
    </div>
  );
}

function ContentLoader({
  type,
  guid,
  onPlay,
  program,
  onRemoveProgram,
  saveAdBreaks,
  selectProgram,
  transcodingTypes = [],
  addCuepoint,
  removeNearestCuepoint,
  trimVideo,
}) {
  const [isLoading, setLoading] = React.useState(!cache[guid]);
  const [content, setContent] = React.useState(cache[guid] ?? null);

  const { formatTimeForTimezone } = useSchedulerContext();

  React.useEffect(() => {
    setLoading(true);
    getContentByType(type, guid)
      .then((program) => {
        setLoading(false);
        setContent(program);
      })
      .catch((e) => console.error(e));
  }, [type, guid]);

  return (
    <div className="epg-program-overview__inner">
      {isLoading ? (
        <Loader />
      ) : (
        <ProgramInformation
          type={type}
          program={content}
          onPlay={onPlay}
          programInfo={program}
          onRemoveProgram={onRemoveProgram}
          saveAdBreaks={saveAdBreaks}
          viewContentListProgram={selectProgram}
          transcodingTypes={transcodingTypes}
          formatTimeForTimezone={formatTimeForTimezone}
          addCuepoint={addCuepoint}
          removeNearestCuepoint={removeNearestCuepoint}
          trimVideo={trimVideo}
        />
      )}
    </div>
  );
}

function EpgProgramOverview({
  program,
  onPlay,
  onRemoveProgram,
  saveAdBreaks,
  viewContentListProgram,
  transcodingTypes,
  addCuepoint,
  removeNearestCuepoint,
  trimVideo,
}) {
  let type = null;
  let guid = null;
  if (program && program?.__gstvMeta) {
    // this is an epg program
    type = program.__gstvMeta.link_type;
    guid = program.__gstvMeta.link_guid;
  } else if (program) {
    // this is a content program
    type = getTypeForContent(program);
    guid = program[`${type}_guid`];
  }

  if (!program || !type || !guid) {
    return <MissingProgram />;
  }

  return (
    <div className="epg-program-overview">
      <ContentLoader
        type={type}
        guid={guid}
        key={guid}
        onPlay={onPlay}
        program={program}
        onRemoveProgram={onRemoveProgram}
        saveAdBreaks={saveAdBreaks}
        selectProgram={viewContentListProgram}
        transcodingTypes={transcodingTypes}
        addCuepoint={addCuepoint}
        removeNearestCuepoint={removeNearestCuepoint}
        trimVideo={trimVideo}
      />
    </div>
  );
}

export default EpgProgramOverview;
