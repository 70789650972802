import React from "react";
import DataTable from "../../../components/data-table/data-table.jsx";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import SeasonItem from "./seasons/season-item.jsx";

export default function SeasonsTab({ guid }) {
  const [expandedRowIndex, setExpandedRowIndex] = React.useState();
  const columns = [
    {
      label: "Season",
      key: "season",
      value: "season_number",
    },
    {
      label: "Episodes",
      key: "episodes",
      value: "episode_count",
    },
    {
      label: "Season Description",
      key: "summary",
      value: "summary",
    },
    {
      label: "Season Artwork",
      key: "artwork",
      renderValue: (row) => (
        <span className="image-group">
          <span>{row.thumbnail}</span>
          <span>{row.medium}</span>
          <span>{row.poster_large}</span>
        </span>
      ),
    },
    {
      label: "",
      key: "actions",
      renderValue: (_, index, __, active) => (
        <div className="u-flex--end u-width-100">
          <button
            onClick={() => {
              if (index === expandedRowIndex) {
                setExpandedRowIndex(null);
              } else {
                setExpandedRowIndex(index);
              }
            }}
            className={`btn btn--icon ${active ? "u-transform-rotate-90" : ""}`}
          >
            <ArrowForwardIosRoundedIcon />
          </button>
        </div>
      ),
    },
  ];

  return (
    <DataTable
      columns={columns}
      url={`/api/series/${guid}/seasons/list`}
      pagination={false}
      expandedRowRender={(row) => <SeasonItem guid={row.season_guid} />}
      expandedRowIndex={expandedRowIndex}
    />
  );
}
