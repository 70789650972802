import React from "react";
import ContentCard from "./components/content-card.jsx";
import { AppHeading } from "../../layout/parts/app-heading.jsx";
import AppBody from "../../layout/parts/app-body.jsx";
import useApiRequest from "../../hooks/use-api-request.js";
import ImageWithFallback from "../../components/image-with-fallback.jsx";
import TabbedContent from "../../components/tabbed-content.jsx";
import EpisodeTab from "./tabs/episode-tab.jsx";
import SeriesTab from "./tabs/series-tab.jsx";
import EpisodeListTab from "./tabs/episode-list-tab.jsx";
import ArtworkTab from "./tabs/artwork-tab.jsx";
import FilesTab from "./tabs/files-tab.jsx";
import { useParams } from "react-router-dom";
import SeasonsTab from "./tabs/seasons-tab.jsx";

function EpisodePage() {
  const { series_guid, episode_guid } = useParams();
  const { isLoading, response: seriesSummaryRequest } = useApiRequest(`/api/episodes/${episode_guid}/series/summary`);

  const breadcrumbs = [
    {
      link: "/content",
      title: "Content",
    },
    {
      link: "/content/library",
      title: "Library",
    },
    {
      title: seriesSummaryRequest?.data
        ? `${seriesSummaryRequest?.data.series_name} ${seriesSummaryRequest?.data.active ? "" : "(Inactive)"}`
        : "...",
      link: `/content/library/series/${series_guid}`,
    },
    {
      title: seriesSummaryRequest?.data
        ? `${seriesSummaryRequest?.data.formatted_name} ${seriesSummaryRequest?.data.active ? "" : "(Inactive)"}`
        : "...",
      link: `/content/library/series/${series_guid}/episode/${episode_guid}`,
    },
  ];

  return (
    <React.Fragment>
      <AppHeading breadcrumbs={breadcrumbs} />
      <AppBody loading={isLoading}>
        {!isLoading && (
          <div className="spread-container">
            <div className="spread-container__middle spread-container__middle--alone">
              <div className="content-page">
                <div className="content-page__media-row">
                  <div className="content-page__media-row__media">
                    <ContentCard noPadding>
                      <ImageWithFallback url={seriesSummaryRequest?.data?.poster} />
                    </ContentCard>
                  </div>
                  <div className="content-page__media-row__description">
                    <ContentCard>
                      <h1 className="content-page__title">{seriesSummaryRequest?.data?.series_name}</h1>
                      <h3 className="content-page__sub-title">Short Summary</h3>
                      <p className="content-page__text">{seriesSummaryRequest?.data?.short_summary}</p>
                      <h3 className="content-page__sub-title">Long Summary</h3>
                      <div className="content-page__text">{seriesSummaryRequest?.data?.long_summary}</div>
                    </ContentCard>
                  </div>
                </div>
                <div className="content-page__body">
                  <TabbedContent
                    tabs={[
                      {
                        label: seriesSummaryRequest.data?.formatted_name,
                        name: "current",
                        component: EpisodeTab,
                        props: {
                          guid: episode_guid,
                        },
                      },
                      {
                        label: "Series",
                        name: "series",
                        component: SeriesTab,
                        props: {
                          guid: series_guid,
                        },
                      },
                      {
                        label: "Seasons",
                        name: "seasons",
                        component: SeasonsTab,
                        props: { guid: series_guid },
                      },
                      {
                        label: "Episodes",
                        name: "episodes",
                        component: EpisodeListTab,
                        props: { guid: series_guid },
                      },
                      {
                        label: "Artwork",
                        name: "art",
                        component: ArtworkTab,
                        props: { guid: episode_guid },
                      },
                      {
                        label: "Files",
                        name: "files",
                        component: FilesTab,
                        props: {
                          type: "episodes",
                          guid: episode_guid,
                          folders: [
                            {
                              label: "Series",
                              url: `/api/episodes/${episode_guid}/files/summary?filter=series`,
                            },
                            {
                              label: "Season",
                              url: `/api/episodes/${episode_guid}/files/summary?filter=season`,
                            },
                            {
                              label: "Episode",
                              url: `/api/episodes/${episode_guid}/files/summary?filter=episode`,
                            },
                          ],
                        },
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </AppBody>
      {/*<ChangeVideoSourceDialog*/}
      {/*  contentType="episode"*/}
      {/*  contentGuid={episode.episode_guid}*/}
      {/*  onClose={changeSourceClosed}*/}
      {/*  isOpen={isChangeSourceOpen}*/}
      {/*/>*/}
    </React.Fragment>
  );
}

export default EpisodePage;
