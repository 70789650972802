import React from "react";
import DataTable from "../../../components/data-table/data-table.jsx";
import ContentCard from "../components/content-card.jsx";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import RestoreFromTrashRoundedIcon from "@mui/icons-material/RestoreFromTrashRounded";
import ManageAssetDialog from "../components/dialogs/manage-asset-dialog.jsx";
import { v4 as uuid } from "uuid";

export default function FilesTab({ folders }) {
  const [assetDialog, setAssetDialog] = React.useState({
    open: false,
    id: "",
    action: "",
  });

  const tableRef = React.useRef();

  function openDialog(assetId, action) {
    setAssetDialog({
      id: assetId,
      open: true,
      action,
    });
  }

  function closeDialog() {
    setAssetDialog({ open: false });
  }

  function getMessageForAction(action) {
    switch (action) {
      case "delete":
        return "Are you sure you want to delete this asset?";
      case "restore":
        return "Are you sure you want to restore this asset?";
      default:
        return "";
    }
  }

  const columns = [
    {
      label: "Type",
      key: "asset_type",
      value: "asset_type",
      width: 10,
      renderValue: (row) => (
        <span className={`data-table__cell__value ${!row.asset_active ? "data-table__cell__value--inactive" : ""}`}>
          {row.asset_type}
        </span>
      ),
    },
    {
      label: "Filename",
      key: "filename",
      value: "filename",
      renderValue: (row) => (
        <span className={`data-table__cell__value ${!row.asset_active ? "data-table__cell__value--inactive" : ""}`}>
          {row.filename}
        </span>
      ),
    },
    {
      label: "Location",
      key: "s3_location",
      value: "s3_location",
      renderValue: (row) => (
        <span className={`data-table__cell__value ${!row.asset_active ? "data-table__cell__value--inactive" : ""}`}>
          {row.s3_location}
        </span>
      ),
    },
    {
      label: "",
      value: "",
      key: "0",
      width: 15,
      renderValue: (row) => (
        <span className="data-table__action-cell">
          {row.asset_active ? (
            <React.Fragment>
              <span
                className="data-table__action-cell__item data-table__icon"
                onClick={() => openDialog(row.asset_id, "delete")}
              >
                <DeleteForeverRoundedIcon />
              </span>
            </React.Fragment>
          ) : (
            <span
              className="data-table__action-cell__item data-table__icon"
              onClick={() => openDialog(row.asset_id, "restore")}
            >
              <RestoreFromTrashRoundedIcon />
            </span>
          )}
        </span>
      ),
    },
  ];
  return (
    <div>
      <div className="u-margin--small--block-end">
        {folders.map((folder, key) => (
          <ContentCard key={key}>
            {folder.label && <div className="content-page__title content-page__title--separator">{folder.label}</div>}
            <DataTable url={folder.url} pagination={false} columns={columns} ref={tableRef} />
          </ContentCard>
        ))}
      </div>
      <ManageAssetDialog
        isOpen={assetDialog.open}
        id={assetDialog.id}
        action={assetDialog.action}
        message={getMessageForAction(assetDialog.action)}
        onClose={closeDialog}
        onConfirm={() => {
          tableRef.current?.refreshList();
        }}
        key={`asset_dialog-${uuid()}`}
      />
    </div>
  );
}
