import React from "react";
import ReactDatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import Loader from "../../../common/loader.jsx";
import { BASE_URL } from "../../../requests/api-requests.js";
import { v4 as uuid } from "uuid";
import TrashPlanDialog from "./dialogs/trash-plan-dialog.jsx";
import ProcessPlanDialog from "./dialogs/process-plan-dialog.jsx";
import CopyPlanDialog from "./dialogs/copy-plan-dialog.jsx";
import { toast } from "react-toastify";
import { useSchedulerContext } from "../../../providers/scheduler-context.jsx";
import ChannelPlaybackDropdown from "./channel-playback-dropdown.jsx";
import format from "date-fns/format";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import LoopRoundedIcon from "@mui/icons-material/LoopRounded";
import SimCardDownloadRoundedIcon from "@mui/icons-material/SimCardDownloadRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import PowerSettingsCircle from "../../../assets/power-settings-circle.jsx";
import ViewComfyRoundedIcon from "@mui/icons-material/ViewComfyRounded.js";

function ChannelHeading({
  channel,
  planDate,
  changePlanDate,
  clearPlan,
  saveChannelPlan,
  planId,
  planUpdated,
  scheduleUpdated,
  openChangesDialog,
  toggleTimezone,
  playoutUrl,
  setPlayoutUrl,
  isSaving,
  autosaveEnabled,
  toggleAutosave,
  resetLayout,
}) {
  const [showTrashDialog, setTrashDialog] = React.useState(false);
  const [showProcessDialog, setProcessDialog] = React.useState(false);
  const [showCopyDialog, setCopyDialog] = React.useState(false);
  const [updatedAt, setUpdatedAt] = React.useState();
  const { timezone } = useSchedulerContext();

  function confirmTrash() {
    // we are working with local time here so no need for utc conversion
    if (planDate < new Date()) {
      toast.error("Cannot clear plans that started in the past.");
      return;
    }
    setTrashDialog(true);
  }

  function closeTrash() {
    setTrashDialog(false);
  }

  function openProcessDialog() {
    setProcessDialog(true);
  }

  function closeProcessDialog() {
    setProcessDialog(false);
  }

  function openCopyDialog() {
    setCopyDialog(true);
  }

  function closeCopyDialog() {
    setCopyDialog(false);
  }

  function updatePlanDate(newDate) {
    if (scheduleUpdated()) {
      openChangesDialog(
        "changeDate",
        "Are you sure you wish to change the plan date? (Unsaved changes will be lost)",
        newDate,
      );
    } else {
      changePlanDate(newDate);
    }
  }

  React.useEffect(() => {
    setUpdatedAt(new Date(planUpdated));
  }, [planUpdated]);

  if (!channel || !planDate) {
    return;
  }

  return [
    <div className="channel-heading" key={"header"}>
      <div className="channel-heading__back">
        <Link className="link--base" to="/scheduler" state={{ channel_id: null }}>
          <ArrowBackIosRoundedIcon />
        </Link>
      </div>

      <h2 className="heading heading--medium heading--icon">{channel.display_name}</h2>

      <div className="channel-heading__datepicker">
        <div className="datepicker-container">
          <button
            className="btn--text-primary channel-heading__datepicker__arrow-button"
            onClick={(e) => {
              e.preventDefault();
              let newDate = new Date(planDate.getTime());
              newDate.setDate(newDate.getDate() - 1);
              updatePlanDate(newDate);
            }}
          >
            <ChevronLeftRoundedIcon />
          </button>
          <ReactDatePicker
            selected={new Date(planDate.getTime())}
            onChange={(e) => updatePlanDate(e)}
            clearIcon={null}
            dateFormat="yyyy-MM-dd"
          />
          <button
            className="btn--text-primary channel-heading__datepicker__arrow-button"
            onClick={(e) => {
              e.preventDefault();

              let newDate = new Date(planDate.getTime());
              updatePlanDate(new Date(newDate.setDate(newDate.getDate() + 1)));
            }}
          >
            <ChevronRightRoundedIcon />
          </button>
        </div>
      </div>

      <div className="channel-heading__timezone">
        <div className="channel-heading__timezone-button">
          <button className="btn btn--pill btn--ntrl-min" onClick={toggleTimezone}>
            {timezone}
          </button>
        </div>
      </div>

      {channel.playouts.length > 1 ? (
        <div className="channel-heading__playback-select">
          <ChannelPlaybackDropdown channel={channel} activePlayback={playoutUrl} onChange={setPlayoutUrl} />
        </div>
      ) : null}

      <div className="channel-heading__action-button">
        <div
          className={`channel-heading__autosave-button ${autosaveEnabled ? "channel-heading__autosave-button--active" : ""}`}
        >
          <button
            className={`btn btn--icon `}
            onClick={() => {
              toggleAutosave();
              document.activeElement.blur();
            }}
            aria-label={autosaveEnabled ? "Disable autosave" : "Enable autosave"}
            data-balloon-pos="right"
          >
            <PowerSettingsCircle size={28} />
          </button>
        </div>
      </div>

      <div className="channel-heading__action-button">
        <div className="channel-heading__layout-reset-button">
          <button
            className="btn btn--icon"
            onClick={() => {
              resetLayout();
              document.activeElement.blur();
            }}
            aria-label="Reset layout"
            data-balloon-pos="right"
          >
            <ViewComfyRoundedIcon size={28} />
          </button>
        </div>
      </div>

      <div className="channel-heading__actions">
        <div className="channel-heading__last-updated">
          {updatedAt ? `Last change saved at: ${updatedAt.toLocaleDateString()} ${updatedAt.toLocaleTimeString()}` : ""}
        </div>
        <button className="btn btn--icon" onClick={openCopyDialog} aria-label="Copy Plan" data-balloon-pos="down">
          <ContentCopyRoundedIcon />
        </button>
        {channel.freeview_service_id ? (
          <a
            className="btn btn--icon"
            href={`${BASE_URL}/feeds/epgs/everyonetv?channel=${channel.name}&date=${format(planDate, "y-MM-dd")}&days=0`}
            download
            aria-label="Download Freeview EPG"
            data-balloon-pos="down"
          >
            <SimCardDownloadRoundedIcon />
          </a>
        ) : null}
        <button className="btn btn--icon" onClick={openProcessDialog} aria-label="Process Plan" data-balloon-pos="down">
          <LoopRoundedIcon />
        </button>
        <button className="btn btn--icon" onClick={confirmTrash} aria-label="Clear Plan" data-balloon-pos="down">
          <DeleteForeverRoundedIcon />
        </button>
        {isSaving ? (
          <Loader width={25} height={25} />
        ) : (
          <button className="btn btn--icon" onClick={saveChannelPlan} aria-label="Save Plan" data-balloon-pos="down">
            <SaveRoundedIcon />
          </button>
        )}
      </div>
    </div>,
    <TrashPlanDialog
      planDate={planDate}
      isOpen={showTrashDialog}
      onClose={closeTrash}
      onSuccess={clearPlan}
      channelId={channel.channel_id}
      breakSource={channel.channel_breaks}
      updateActiveDate={changePlanDate}
      key={`trash_dialog-${uuid()}`}
    />,
    <ProcessPlanDialog
      planId={planId}
      isOpen={showProcessDialog}
      channelId={channel.channel_id}
      onClose={closeProcessDialog}
      key={`process_dialog-${uuid()}`}
    />,
    <CopyPlanDialog
      planDate={planDate}
      isOpen={showCopyDialog}
      onClose={closeCopyDialog}
      channelId={channel.channel_id}
      updateActiveDate={changePlanDate}
      key={`copy_plan_dialog-${uuid()}`}
    />,
  ];
}

export default ChannelHeading;
