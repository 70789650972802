import React from "react";
import { getTypeForContent } from "../../../epg-editor/utils/accessors.js";
import LibraryItemThumbnail from "./library-item-thumbnail.jsx";
import LibraryItemRow from "./library-item-row.jsx";
import GridViewIcon from "@mui/icons-material/GridView";
import ReorderIcon from "@mui/icons-material/Reorder";
import getClassList from "../../../../functions/get-class-list.js";

export default function LibraryList({ results }) {
  const LIBRARY_LIST_LOCAL_STORAGE_KEY = "__gstv_ui_list_view_";
  const [mode, setMode] = React.useState(window.localStorage.getItem(LIBRARY_LIST_LOCAL_STORAGE_KEY) ?? "rows");

  React.useEffect(() => {
    window.localStorage.setItem(LIBRARY_LIST_LOCAL_STORAGE_KEY, mode);
  }, [mode]);

  function constructUrlForItem(item) {
    switch (getTypeForContent(item)) {
      case "feature":
        return `/content/library/feature/${item.feature_guid}`;

      case "episode":
        return `/content/library/series/${item.season.series.series_guid}/episode/${item.episode_guid}`;

      case "promo":
        return `/content/library/promo/${item.promo_guid}`;

      case "series":
        return `/content/library/series/${item.series_guid}`;
    }
  }

  function hasError(item) {
    return !item.asset_duration && !item.duration;
  }

  function getItemClass(item) {
    return getClassList({
      "library-list__item": true,
      "library-list__item--warning": hasError(item),
    });
  }

  return (
    <React.Fragment>
      <div className="library-list-actions">
        <button
          className={`btn btn--icon u-display--flex-vh-center ${mode === "rows" ? "btn--primary" : ""}`}
          onClick={() => setMode("rows")}
          disabled={mode === "rows"}
        >
          <ReorderIcon />
        </button>
        <button
          className={`btn btn--icon u-display--flex-vh-center ${mode === "thumbnails" ? "btn--primary" : ""}`}
          onClick={() => setMode("thumbnails")}
          disabled={mode === "thumbnails"}
        >
          <GridViewIcon />
        </button>
      </div>
      <div className={`library-list ${mode === "thumbnails" ? "library-list--thumbnails" : "library-list--rows"}`}>
        {results.map((result, index) => (
          <a
            href={constructUrlForItem(result)}
            className={getItemClass(result)}
            key={`library-list__item__${index}`}
            {...(hasError(result)
              ? {
                  ["aria-label"]: "No video found for this content",
                  ["data-balloon-pos"]: "up",
                }
              : {})}
          >
            {mode === "thumbnails" ? <LibraryItemThumbnail item={result} /> : <LibraryItemRow item={result} />}
          </a>
        ))}
      </div>
    </React.Fragment>
  );
}
