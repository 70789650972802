import React from "react";
import ContentCard from "././library/components/content-card";
import { getAllPlayouts } from "../requests/api-requests";
import { toast } from "react-toastify";
import PlayoutChannelFilter from "./playout/playout-channel-filter";
import PlayoutScheduleAllDialog from "./playout/playout-schedule-all-dialog";
import { v4 as uuid } from "uuid";
import DirectionsRunRoundedIcon from "@mui/icons-material/DirectionsRunRounded";
import ChangeCircleRoundedIcon from "@mui/icons-material/ChangeCircleRounded";
import { AppHeading } from "../layout/parts/app-heading.jsx";
import AppBody from "../layout/parts/app-body.jsx";
import DataTable from "../components/data-table/data-table.jsx";
import QueueTranscodeDialog from "./components/queue-transcode-dialog.jsx";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import PlayCircleRoundedIcon from "@mui/icons-material/PlayCircleRounded";
import SyncRoundedIcon from "@mui/icons-material/SyncRounded";
import PlayoutDeleteDialog from "./playout/playout-delete-dialog.jsx";
import PlayoutScheduleDialog from "./playout/playout-schedule-dialog.jsx";
import { format, isBefore } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import TranscodingStatusIcon from "./components/transcoding-status-icon.jsx";

function PlayoutsPage() {
  const [playouts, setPlayouts] = React.useState([]);
  const [activePlayout, setActivePlayout] = React.useState({});
  const [scheduleAllDialog, setScheduleAllDialog] = React.useState({
    open: false,
  });

  const [deleteDialog, setDeleteDialog] = React.useState({
    open: false,
  });

  const [scheduleDialog, setScheduleDialog] = React.useState({
    open: false,
  });

  const [transcodeDialog, setTranscodeDialog] = React.useState({
    open: false,
    contentType: null,
    contentId: null,
  });

  const getProgramTranscodingStatus = React.useCallback(
    (program) => {
      if (activePlayout?.destination_output === "hls") {
        return program.plan_program.program.content_video?.hls_status;
      } else if (activePlayout?.destination_output === "dash") {
        return program.plan_program.program.content_video?.dash_status;
      }

      return;
    },
    [activePlayout?.destination_output],
  );

  const columns = React.useMemo(
    () => [
      {
        label: "Program",
        key: "program_title",
        value: "plan_program.program.title",
        width: 22,
      },
      {
        label: "Start",
        key: "start",
        value: "",
        width: 15,
        renderValue: (row) => (
          <span className="data-table__cell__value">
            {format(new Date(row.actual_start_time ?? row.expected_start_time), "Y-MM-dd HH:mm:ss")}
          </span>
        ),
      },
      {
        label: "End",
        key: "end",
        value: "",
        width: 15,
        renderValue: (row) => (
          <span className="data-table__cell__value">
            {format(new Date(row.actual_end_time ?? row.expected_end_time), "Y-MM-dd HH:mm:ss")}
          </span>
        ),
      },
      {
        label: "Start (UTC)",
        key: "start_utc",
        value: "",
        width: 15,
        renderValue: (row) => (
          <span className="data-table__cell__value">
            {formatDates(row.actual_start_time ?? row.expected_start_time)}
          </span>
        ),
      },
      {
        label: "End (UTC)",
        key: "end_utc",
        value: "genre",
        width: 15,
        renderValue: (row) => (
          <span className="data-table__cell__value">{formatDates(row.actual_end_time ?? row.expected_end_time)}</span>
        ),
      },
      {
        label: "Status",
        key: "status",
        value: "",
        width: 10,
        align: "center",
        renderValue: (row) => (
          <span className={`data-table__cell__value data-table__icon`}>
            <TranscodingStatusIcon status={getProgramTranscodingStatus(row)} type={activePlayout?.destination_output} />
          </span>
        ),
      },
      {
        label: "",
        value: "",
        key: "0",
        width: 8,
        renderValue: (row, index, array) => (
          <span className="data-table__cell data-table__action-cell">
            {[undefined, false, null, "failed", "initialised"].includes(getProgramTranscodingStatus(row)) ? (
              <span className="data-table__action-cell__item data-table__icon" onClick={() => openTranscodeDialog(row)}>
                <SyncRoundedIcon />
              </span>
            ) : null}

            {["pending", "failed"].includes(row.status) &&
            isProgramMutable(array, index) &&
            (!isProgramMutable(array, index - 1) || ["scheduled", "failed"].includes(array[index - 1].status)) ? (
              <span className="data-table__action-cell__item data-table__icon" onClick={() => openScheduleDialog(row)}>
                <PlayCircleRoundedIcon />
              </span>
            ) : null}

            {["scheduled", "pending", "failed"].includes(row.status) && isAfterNow(row.expected_start_time) ? (
              <span className="data-table__action-cell__item data-table__icon" onClick={() => openDeleteDialog(row)}>
                <CancelRoundedIcon />
              </span>
            ) : null}
          </span>
        ),
      },
    ],
    [activePlayout?.destination_output, getProgramTranscodingStatus],
  );

  React.useEffect(() => {
    getAllPlayouts()
      .then((response) => {
        setPlayouts(response.filter((playout) => playout.channel));
      })
      .catch((error) => {
        console.error(error);
        toast.error("There was an error retrieving playouts. Please contact support.");
      });
  }, []);

  function onPlayoutSelect(playout) {
    setActivePlayout({ ...playout });
  }

  function openScheduleAllDialog(playout) {
    if (Object.keys(activePlayout).length) {
      setScheduleAllDialog((prev) => ({
        ...prev,
        open: true,
        playoutId: playout.playoutId,
      }));
    } else {
      toast.info("Please select a channel");
    }
  }

  function closeScheduleAllDialog() {
    setScheduleAllDialog({
      open: false,
    });
  }

  function refreshList() {
    setActivePlayout((prev) => ({ ...prev }));
  }

  function formatDates(date) {
    return formatInTimeZone(date, "UTC", "Y-MM-dd HH:mm:ss");
  }

  function openDeleteDialog(program) {
    setDeleteDialog((prev) => ({
      ...prev,
      open: true,
      program: program,
    }));
  }

  function closeDeleteDialog() {
    setDeleteDialog({
      open: false,
    });
  }

  function openScheduleDialog(program) {
    setScheduleDialog((prev) => ({
      ...prev,
      open: true,
      program: program,
    }));
  }

  function closeScheduleDialog() {
    setScheduleDialog({
      open: false,
    });
  }

  function openTranscodeDialog(program) {
    setTranscodeDialog({
      open: true,
      contentId: program.plan_program.link_id,
      contentType: program.plan_program.link_type,
    });
  }

  function closeTranscodeDialog() {
    setTranscodeDialog({
      open: false,
      contentId: null,
      contentType: null,
    });
  }

  function isAfterNow(endTime) {
    return new Date(endTime) > new Date();
  }

  function isProgramMutable(array, currentIndex) {
    // everything in the past is mutable
    if (!array[currentIndex]) {
      return false;
    }

    if (!array[currentIndex - 1]) {
      return isBefore(new Date(), new Date(array[currentIndex].expected_start_time));
    }

    return isBefore(new Date(), new Date(array[currentIndex].expected_start_time));
  }

  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={[
          {
            link: "/playouts",
            title: "Playouts",
          },
        ]}
        rightActions={[
          {
            icon: DirectionsRunRoundedIcon,
            onClick: () => openScheduleAllDialog(activePlayout),
            balloonLabel: "Run Schedule",
          },
          {
            icon: ChangeCircleRoundedIcon,
            onClick: refreshList,
            balloonLabel: "Refresh list",
          },
        ]}
      />
      <AppBody>
        <div className="spread-container">
          <div className="spread-container__middle spread-container__middle--alone">
            <div className="gw">
              <div className="g g--1-of-1">
                <div className="playouts-filter">
                  <div className="playouts-filter__select">
                    <PlayoutChannelFilter playoutChannels={playouts} onChange={onPlayoutSelect} />
                  </div>
                </div>
              </div>
            </div>

            <ContentCard noPadding>
              {activePlayout?.playoutId ? (
                <DataTable
                  url={activePlayout?.playoutId ? `/api/playouts/${activePlayout.playoutId}/programs` : ""}
                  columns={columns}
                />
              ) : null}
            </ContentCard>
          </div>
        </div>
      </AppBody>
      <PlayoutScheduleAllDialog
        isOpen={scheduleAllDialog.open}
        onClose={closeScheduleAllDialog}
        onSuccess={refreshList}
        playoutId={scheduleAllDialog.playoutId}
        key={`schedule-programs-dialog__${uuid()}`}
      />
      <PlayoutDeleteDialog
        isOpen={deleteDialog.open}
        program={deleteDialog?.program}
        onClose={closeDeleteDialog}
        onSuccess={refreshList}
        key={`clear-programs-dialog__${uuid()}`}
      />
      <PlayoutScheduleDialog
        isOpen={scheduleDialog.open}
        program={scheduleDialog?.program}
        onClose={closeScheduleDialog}
        onSuccess={refreshList}
        key={`schedule-program-dialog__${uuid()}`}
      />
      {activePlayout?.destination_output ? (
        <QueueTranscodeDialog
          isOpen={transcodeDialog.open}
          contentType={transcodeDialog?.contentType}
          contentId={transcodeDialog?.contentId}
          onClose={closeTranscodeDialog}
          onSuccess={refreshList}
          key={`transcode-dialog__${uuid()}`}
          transcodeType={activePlayout?.destination_output}
        />
      ) : null}
      {Object.keys(activePlayout).length && activePlayout.status === "scheduling" ? (
        <div className="playout-alert">Scheduling in progress</div>
      ) : null}
    </React.Fragment>
  );
}

export default PlayoutsPage;
