import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { dateToHourMinutes, secondsToHourMinutesSeconds } from "../../common/duration-formatting.js";
import differenceInSeconds from "date-fns/differenceInSeconds";
import { addMinutes, endOfDay, startOfDay } from "date-fns";
import dateIsBetween from "../../common/date-is-between.js";
import { toast } from "react-toastify";
import CopySegmentDialog from "../../pages/epg-editor/components/dialogs/copy-segment-dialog.jsx";
import { v4 as uuid } from "uuid";
import DraggableProgram from "./draggable-program.jsx";
import { isUtcPast } from "../../common/is-utc-past.js";
import { getTimezoneOffset } from "date-fns-tz";
import { useSchedulerContext } from "../../providers/scheduler-context.jsx";
import useSegments from "./hooks/useSegments.jsx";
import { getHeightStyleFromSeconds } from "./helpers/style-helpers.js";
import CalendarMarkers from "./calendarMarkers.jsx";
import CurrentTimeMarker from "../../pages/epg-editor/components/current-time-marker.jsx";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";

function getHeightFromSeconds(secondsPerSection, pixelsPerSection, seconds) {
  const sections = seconds / secondsPerSection;
  const pixels = sections * pixelsPerSection;

  return pixels;
}

function findFurthestShortProgram(
  fromIndex,
  list,
  shortHeight,
  secondsPerSection,
  pixelsPerSection,
  direction,
  distance = 0,
) {
  if (!list[fromIndex]) {
    return distance;
  }

  const current = getHeightFromSeconds(
    secondsPerSection,
    pixelsPerSection,
    list[fromIndex].__gstvMeta.total_duration_seconds,
  );

  if (current > shortHeight) {
    return distance;
  }

  return findFurthestShortProgram(
    fromIndex + direction,
    list,
    shortHeight,
    secondsPerSection,
    pixelsPerSection,
    direction,
    distance + 1,
  );
}

// offset can be [-1, y], [0, y], or [1, y]
function getSmallOffset(fromIndex, list, shortHeight, secondsPerSection, pixelsPerSection) {
  const shortProgramsBehind =
    findFurthestShortProgram(fromIndex, list, shortHeight, secondsPerSection, pixelsPerSection, -1) - 1;
  const shortProgramsAhead =
    findFurthestShortProgram(fromIndex, list, shortHeight, secondsPerSection, pixelsPerSection, 1) - 1;

  let x = shortProgramsBehind !== 0 ? (shortProgramsBehind % 3) - 1 : 0;
  const y = shortProgramsBehind !== 0 ? Math.floor(shortProgramsBehind / 3) : 0;

  if (shortProgramsAhead > 0 && shortProgramsBehind === 0 && x === 0) {
    x -= 1;
  }

  return [x, y];
}

const SMALL_PROGRAM_MAX_HEIGHT = 30;

function getPrependedMargin(fromIndex, list, shortHeight, secondsPerSection, pixelsPerSection) {
  const indexHeight = getHeightFromSeconds(
    secondsPerSection,
    pixelsPerSection,
    list[fromIndex].__gstvMeta.total_duration_seconds,
  );
  if (indexHeight <= shortHeight) {
    return 0;
  }

  const shortProgramsBehind = findFurthestShortProgram(
    fromIndex - 1,
    list,
    shortHeight,
    secondsPerSection,
    pixelsPerSection,
    -1,
  );

  let marginAdjustment = 0;

  if (shortProgramsBehind > 0) {
    for (let i = 1; i < shortProgramsBehind + 1; i++) {
      const current = getHeightFromSeconds(
        secondsPerSection,
        pixelsPerSection,
        list[fromIndex - i].__gstvMeta.total_duration_seconds,
      );

      marginAdjustment += current;
    }
  }

  return marginAdjustment;
}

function VerticalScheduler({
  epg,
  planBreaks,
  droppableId,
  planDate,
  addPlanBreak,
  deletePlanBreak,
  selectProgram,
  onPlay,
  removeProgram,
  copySegment,
  updateProgramDuration,
  setCustomTimeRange,
  dropsDisabled,
  onProgramDrag,
  customRange,
}) {
  const [showCopyDialog, setShowCopyDialog] = React.useState(false);
  const [targetSegmentIndex, setTargetSegmentIndex] = React.useState(0);
  const [markerPopoutWidth, setMarkerPopoutWidth] = React.useState();
  const calendarDiv = React.useRef();
  const timelineContainerRef = React.useRef();
  const { timezone, toOffsetDate } = useSchedulerContext();
  const segments = useSegments(planDate, planBreaks, epg);
  const timezoneOffset = timezone !== "UTC" ? getTimezoneOffset("Europe/London", planDate) / 1000 : 0;
  const pixelsPerSection = 4;
  const secondsPerSection = 60; // change this when changing active availableMarkerMinuteSpread value
  const pixelsPerSecond = (1 / secondsPerSection) * pixelsPerSection;

  React.useLayoutEffect(() => {
    const observer = new ResizeObserver(() => {
      setMarkerPopoutWidth(getMarkerPopOutWidth());
    });

    if (calendarDiv.current) {
      observer.observe(calendarDiv.current);
    } else {
      // hack to make sure we avoid timing issues
      setTimeout(() => {
        if (calendarDiv.current) {
          observer.observe(calendarDiv.current);
        }
      }, 300);
    }

    return () => observer.disconnect();
  }, []);

  const changeZoomLevel = React.useCallback(
    (start, end) => {
      setCustomTimeRange([start, end]);
    },
    [setCustomTimeRange],
  );

  // early exit if we don't have all the information
  if (planBreaks.length === 0) {
    return null;
  }

  function getMarkerPopOutWidth() {
    if (calendarDiv.current) {
      return `${calendarDiv.current.clientWidth}px`;
    }

    return "600px";
  }

  function getTimeRemainingInSegment(segment) {
    let remaining = 0;
    if (segment.items.length) {
      remaining = differenceInSeconds(segment.end, segment.items[segment.items.length - 1].till);
    } else {
      // check if there are shows that end in this segment
      const candidates = epg.filter((program) => {
        return dateIsBetween(program.till, segment.start, segment.end);
      });
      if (candidates.length) {
        remaining = differenceInSeconds(segment.end, candidates[candidates.length - 1].till);
      }
    }

    if (remaining <= 0) {
      return "";
    }
    return secondsToHourMinutesSeconds(remaining);
  }

  function onProgramClick(program) {
    selectProgram(program);
    onPlay(program.__gstvMeta.video_asset.asset_id);
  }

  function addBreakAtPosition(date) {
    const [segment] = segments.filter((segment) => {
      return dateIsBetween(date, segment.start, segment.end, "(]");
    });

    if (!segment) {
      toast.error("Unable to add a break there.");
      return;
    }

    // Fail if the proposed break is earlier than the end of the last program in the segment, i.e. overlaps any program
    if (segment.items.length > 0) {
      if (date < segment.items[segment.items.length - 1].till) {
        toast.error("Break cannot overlap existing program");
        return;
      }
    }

    if (isUtcPast(date)) {
      toast.error("Cannot add breaks to the past.");
      return;
    }

    addPlanBreak(date);
  }

  function openCopyDialog(targetSegmentIndex) {
    setTargetSegmentIndex(targetSegmentIndex);
    setShowCopyDialog(true);
  }

  function closeCopyDialog() {
    setShowCopyDialog(false);
  }

  function executeSegmentCopy(sourceSegmentIndex, targetSegmentIndex) {
    if (isUtcPast(segments[targetSegmentIndex].start)) {
      toast.success("Cannot copy into segment that started in the past.");
      return;
    }

    copySegment(
      JSON.parse(
        JSON.stringify(
          segments[sourceSegmentIndex].items.map((item) => ({
            ...item,
            id: null,
            __gstvMeta: { ...item.__gstvMeta, program_id: null },
          })),
        ),
      ),
      segments[targetSegmentIndex].start,
      segments[targetSegmentIndex].items ? segments[targetSegmentIndex].items.length : 0,
    );
    toast.success("Copy Successful");
    closeCopyDialog();
  }

  function getProgramVerticalOffset(segment) {
    return getHeightStyleFromSeconds(
      (segment.items[segment.items.length - 1].till - segment.end) / 1000,
      secondsPerSection,
      pixelsPerSection,
    );
  }

  function getLastProgramForSegment(sIndex) {
    return segments[sIndex].items[segments[sIndex].items.length - 1];
  }

  function addBreak(minutesFromStartOfDay) {
    const date = addMinutes(startOfDay(planDate), minutesFromStartOfDay);
    addBreakAtPosition(date);
  }

  let baseDate = startOfDay(planDate);
  let endDate = endOfDay(planDate);
  endDate.setSeconds(60); // push end to 24:00:00

  if (customRange.length) {
    baseDate = customRange[0];
    endDate = customRange[1];
  }

  return (
    <div className="vertical-scheduler" ref={timelineContainerRef}>
      <CalendarMarkers
        planDate={planDate}
        timezoneOffset={timezoneOffset}
        addBreak={addBreak}
        containerRef={timelineContainerRef}
        secondsPerSection={secondsPerSection}
        pixelsPerSection={pixelsPerSection}
        popoutWidth={markerPopoutWidth}
        changeZoomLevel={changeZoomLevel}
      />
      <div className="vertical-scheduler-calendar" ref={calendarDiv}>
        <CurrentTimeMarker
          timeRange={[baseDate, endDate]}
          pixelsPerSecond={pixelsPerSecond}
          date={planDate}
          direction="horizontal"
          considerRange={false}
        />
        {segments.map((seg, sIndex) => (
          <div
            className="vertical-scheduler-calendar__segment"
            key={sIndex}
            style={{
              height: getHeightStyleFromSeconds(
                differenceInSeconds(seg.end, seg.start),
                secondsPerSection,
                pixelsPerSection,
              ),
              ...(sIndex > 0 && getLastProgramForSegment(sIndex - 1)?.till > segments[sIndex - 1].end
                ? { paddingTop: `${getProgramVerticalOffset(segments[sIndex - 1])}` }
                : {}),
            }}
          >
            <Droppable
              droppableId={`${droppableId}-${seg.label}`}
              direction="vertical"
              key={sIndex}
              ignoreContainerClipping={true}
              // because plan date is in the user's timezone we don't need to do a utc comparison
              isDropDisabled={dropsDisabled || endOfDay(planDate) < new Date()}
            >
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={`vertical-scheduler-calendar__plan`}
                >
                  <div className="vertical-scheduler-calendar__bottom">
                    <div className="vertical-scheduler-calendar__pill vertical-scheduler-calendar__pill--secondary">
                      <div className="vertical-scheduler-calendar__pill__text">{getTimeRemainingInSegment(seg)}</div>
                    </div>
                    <div className="vertical-scheduler-calendar__pill">
                      <span className="vertical-scheduler-calendar__pill__text">
                        {dateToHourMinutes(toOffsetDate(seg.end))}
                      </span>
                      {segments[sIndex + 1] ? (
                        <button
                          className="btn btn--icon vertical-scheduler__btn-copy"
                          onClick={() => openCopyDialog(sIndex)}
                        >
                          <ContentCopyRoundedIcon />
                        </button>
                      ) : null}
                      {segments[sIndex + 1] && segments[sIndex + 1].type === "plan" ? (
                        <button
                          className="btn btn--icon vertical-scheduler__btn-delete"
                          onClick={() => deletePlanBreak(segments[sIndex + 1].label)}
                        >
                          <CancelRoundedIcon />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  {seg.items.map((program, index) => (
                    <DraggableProgram
                      program={program}
                      segment={seg}
                      onProgramClick={onProgramClick}
                      updateDuration={updateProgramDuration}
                      removeProgram={removeProgram}
                      secondsPerSection={secondsPerSection}
                      pixelsPerSection={pixelsPerSection}
                      index={index}
                      key={index}
                      onProgramDrag={onProgramDrag}
                      smallOffset={getSmallOffset(
                        index,
                        seg.items,
                        SMALL_PROGRAM_MAX_HEIGHT,
                        secondsPerSection,
                        pixelsPerSection,
                      )}
                      smallDuration={SMALL_PROGRAM_MAX_HEIGHT}
                      getHeightFromSeconds={getHeightFromSeconds.bind(null, secondsPerSection, pixelsPerSection)}
                      prependMargin={getPrependedMargin(
                        index,
                        seg.items,
                        SMALL_PROGRAM_MAX_HEIGHT,
                        secondsPerSection,
                        pixelsPerSection,
                      )}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <div className="vertical-scheduler__extra"></div>
          </div>
        ))}
      </div>
      <CopySegmentDialog
        isOpen={showCopyDialog}
        onClose={closeCopyDialog}
        onValidationPass={executeSegmentCopy}
        segments={segments}
        referenceIndex={targetSegmentIndex}
        key={`copy_dialog-${uuid()}`}
      />
    </div>
  );
}

export default VerticalScheduler;
