import React from "react";
import { createContext } from "react";
import { getTimezoneOffset } from "date-fns-tz";
import { addMinutes, format } from "date-fns";

const SchedulerContext = createContext({
  timezone: "",
  toUtcDate: (t) => t,
  toOffsetDate: (t) => t,
  formatTimeForTimezone: (t) => t,
  fromLocalTime: (t) => t,
});

function useSchedulerProvider(currentTimezone) {
  const [state, setState] = React.useState({
    timezone: currentTimezone ?? "UTC",
  });

  function toOffsetDate(dateTime) {
    if (state.timezone === "Europe/London") {
      return fromUtcToTimezoneDate(dateTime);
    }

    return dateTime;
  }

  function formatTimeForTimezone(dateTime) {
    return format(toOffsetDate(dateTime), "HH:mm:ss");
  }

  function toUtcDate(date) {
    return fromTimezoneToUtcDate(date);
  }

  const fromLocalTime = React.useCallback(
    (localTime) => {
      if (state.timezone === "UTC") {
        return fromTimezoneToUtcDate(localTime);
      } else if (state.timezone === "Europe/London") {
        return fromTimezoneToTimezone(localTime, state.timezone);
      }

      return localTime;
    },
    [state.timezone],
  );

  React.useEffect(() => {
    setState({
      timezone: currentTimezone,
    });
  }, [currentTimezone]);

  return {
    ...state,
    toUtcDate,
    toOffsetDate,
    formatTimeForTimezone,
    fromLocalTime,
  };
}

function fromUtcToTimezoneDate(utcDate) {
  let offsetDate = new Date(utcDate);
  const modifier = offsetDate < 0 ? -1 : 1;
  offsetDate = addMinutes(offsetDate, (getTimezoneOffset("Europe/London", utcDate) / 60000) * modifier);
  return offsetDate;
}

function fromTimezoneToUtcDate(timezoneDate) {
  let offsetDate = new Date(timezoneDate);
  const modifier = offsetDate.getTimezoneOffset() < 0 ? 1 : -1;
  offsetDate = addMinutes(offsetDate, timezoneDate.getTimezoneOffset() * modifier);
  return offsetDate;
}

function fromTimezoneToTimezone(timezoneDate, targetTimezone = "Europe/London") {
  let offsetDate = new Date(timezoneDate);
  const localOffset = timezoneDate.getTimezoneOffset() * -1; // make consistent with getTimezoneOffset result
  const targetOffset = getTimezoneOffset(targetTimezone, timezoneDate) / 60000;
  const finalOffset = targetOffset - localOffset;

  const modifier = offsetDate.getTimezoneOffset() < 0 ? 1 : -1;

  offsetDate = addMinutes(offsetDate, finalOffset * modifier);
  return offsetDate;
}

export function useSchedulerContext() {
  return React.useContext(SchedulerContext);
}

export default function SchedulerProvider({ children, timezone }) {
  const context = useSchedulerProvider(timezone);
  return <SchedulerContext.Provider value={context}>{children}</SchedulerContext.Provider>;
}
