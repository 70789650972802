import React from "react";
import Plyr from "@robguy21/plyr-react";
import { getAsset } from "../../requests/api-requests.js";
import { getPlayerControls } from "./player-controls.jsx";
import PlayerLoader from "./player-loader.jsx";
import usePlyrId from "./use-plyr-id.js";
import Hls from "hls.js";
import dashjs from "dashjs";

const commonOptions = {
  i18n: {
    restart: "Restart",
    rewind: "Rewind {seektime}s",
    play: "Play",
    pause: "Pause",
    fastForward: "Forward {seektime}s",
    seek: "Seek",
    seekLabel: "{currentTime} of {duration}",
    played: "Played",
    buffered: "Buffered",
    currentTime: "Current time",
    duration: "Duration",
    volume: "Volume",
    mute: "Mute",
    unmute: "Unmute",
    enableCaptions: "Enable captions",
    disableCaptions: "Disable captions",
    download: "Download",
    enterFullscreen: "Enter fullscreen",
    exitFullscreen: "Exit fullscreen",
    frameTitle: "Player for {title}",
    captions: "Captions",
    settings: "Settings",
    menuBack: "Go back to previous menu",
    speed: "Speed",
    normal: "Normal",
    quality: "Quality",
    loop: "Loop",
  },
};

const hlsPlayerOptions = {
  controls: getPlayerControls(true),
  ...commonOptions,
};

const hlsPlayerOptionsLimited = {
  controls: getPlayerControls(false),
  ...commonOptions,
};

const editorPlayerOptions = {
  controls: getPlayerControls(true, true),
  ...commonOptions,
};

const editorPlayerOptionsWithChangeSource = {
  controls: getPlayerControls(true, true, true),
  ...commonOptions,
};

const editorPlayerOptionsLimited = {
  controls: getPlayerControls(false, true),
  ...commonOptions,
};

const editorPlayerOptionsLimitedWithChangeSource = {
  controls: getPlayerControls(false, true, true),
  ...commonOptions,
};

function Player({
  cuepoints = null,
  source,
  trimPoint = null,
  isHls = false,
  isDash = false,
  id = null,
  addCuepoint = null,
  removeNearestCuepoint = null,
  trimVideo = null,
  title = null,
  limitedOptions = false,
  editControls = true,
  changeSource = null,
}) {
  const playerRef = React.useRef();
  const [isLoading, setLoading] = React.useState(false);
  const playerId = usePlyrId(playerRef);
  const containerRef = React.useRef();
  const [playerDims, setPlayerDims] = React.useState({ height: "160px", width: "90px" });

  React.useLayoutEffect(() => {
    const loadVideo = async () => {
      const video = document.getElementById(id);
      const hls = new Hls();
      hls.loadSource(source);
      hls.attachMedia(video);
      playerRef.current.plyr.media = video;

      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        playerRef.current.plyr.play();
        playerRef.current.plyr.muted = true;
      });
    };

    const loadDashVideo = async () => {
      const dash = dashjs.MediaPlayer().create();
      const video = document.getElementById(id);

      dash.initialize(video, source, true);
    };

    if (playerRef.current?.plyr) {
      // enforce options
      playerRef.current.plyr.muted = true;
      playerRef.current.plyr.autoplay = true;

      // update source
      if (isHls) {
        loadVideo().then().catch();
      } else if (isDash) {
        loadDashVideo().then().catch();
      } else {
        playerRef.current.plyr.source = {
          type: "video",
          title: "",
          sources: [
            {
              src: source,
              type: "video/mp4",
              size: 720,
            },
          ],
        };
      }
    }
  }, [source, isHls, isDash, id]);

  React.useLayoutEffect(() => {
    const observer = new ResizeObserver(() => {
      if (containerRef.current?.parentElement) {
        let parentElement = containerRef.current.parentElement;
        let videoWidth = parentElement.getBoundingClientRect().width;
        let videoHeight = parentElement.getBoundingClientRect().width / (16 / 9);

        if (videoHeight > parentElement.getBoundingClientRect().height) {
          videoHeight = parentElement.getBoundingClientRect().height;
          videoWidth = parentElement.getBoundingClientRect().height * (16 / 9);
        }

        setPlayerDims({ height: `${videoHeight}px`, width: `${videoWidth}px` });
      }
    });

    if (containerRef.current?.parentElement) {
      observer.observe(containerRef.current.parentElement);
    } else {
      // hack to make sure we avoid timing issues
      setTimeout(() => {
        if (containerRef.current?.parentElement) {
          observer.observe(containerRef.current.parentElement);
        }
      }, 300);
    }

    return () => observer.disconnect();
  }, []);

  function updateLoader(playerId, event) {
    if (event.type === "loadstart") {
      setLoading(true);
    } else if (event.type === "canplay" && playerId === event.detail.plyr.id) {
      setLoading(false);
    }
  }

  function handleError(event) {
    console.error(event);
  }

  React.useEffect(() => {
    const elementRef = playerRef.current;
    function updateMarkers() {
      if (cuepoints || trimPoint) {
        const markers = [
          ...(cuepoints && cuepoints.length ? cuepoints : []),
          ...(trimPoint
            ? [
                {
                  time: trimPoint,
                  label: "Trimmed",
                  type: "trim-point",
                },
              ]
            : []),
        ];
        playerRef.current.plyr.updateMarkers(markers);
      }
    }

    const playerId = playerRef.current?.plyr?.id;

    function onAddCuepoint() {
      if (!playerRef.current?.plyr?.id) {
        console.error("Could not add cuepoint to video because no valid plyr element found");
        return;
      }

      const time = Math.round(playerRef.current.plyr.currentTime);
      if (addCuepoint) {
        addCuepoint(time);
      }
    }

    function onRemoveCuepoint() {
      if (!playerRef.current?.plyr?.id) {
        console.error("Could not remove cuepoint from video because no valid plyr element found");
        return;
      }

      const time = Math.round(playerRef.current.plyr.currentTime);
      if (removeNearestCuepoint) {
        removeNearestCuepoint(time);
      }
    }

    function onTrimVideo() {
      if (!playerRef.current?.plyr?.id) {
        console.error("Could not trim video because no valid plyr element found");
        return;
      }

      const time = Math.round(playerRef.current.plyr.currentTime);
      if (trimVideo) {
        trimVideo(time);
      }
    }

    function onChangeSource() {
      if (!playerRef.current?.plyr?.id) {
        console.error("Could not trim video because no valid plyr element found");
        return;
      }

      if (changeSource) {
        changeSource();
      }
    }

    function initEditorListeners() {
      const addCuepointQuery = document.querySelector("[data-plyr='add-cuepoint']");
      const removeCuepointQuery = document.querySelector("[data-plyr='remove-cuepoint']");
      const trimQuery = document.querySelector("[data-plyr='trim-video']");
      const changeSource = document.querySelector("[data-plyr='change-source']");

      if (addCuepointQuery) {
        addCuepointQuery.addEventListener("click", onAddCuepoint);
      }

      if (removeCuepointQuery) {
        removeCuepointQuery.addEventListener("click", onRemoveCuepoint);
      }

      if (trimQuery) {
        trimQuery.addEventListener("click", onTrimVideo);
      }

      if (changeSource) {
        changeSource.addEventListener("click", onChangeSource);
      }
    }

    function destroyEditorListeners() {
      const addCuepointQuery = document.querySelector("[data-plyr='add-cuepoint']");
      const removeCuepointQuery = document.querySelector("[data-plyr='remove-cuepoint']");
      const trimQuery = document.querySelector("[data-plyr='trim-video']");

      if (addCuepointQuery) {
        addCuepointQuery.removeEventListener("click", onAddCuepoint);
      }

      if (removeCuepointQuery) {
        removeCuepointQuery.removeEventListener("click", onRemoveCuepoint);
      }

      if (trimQuery) {
        trimQuery.removeEventListener("click", onTrimVideo);
      }
    }

    const updatePlayerLoader = updateLoader.bind(null, playerId);
    if (playerId) {
      updateMarkers();

      elementRef?.plyr.on("loadeddata", updateMarkers);
      elementRef?.plyr.on("error", handleError);
      elementRef?.plyr.on("loadstart", updatePlayerLoader);
      elementRef?.plyr.on("canplay", updatePlayerLoader);

      destroyEditorListeners(); // looks like not all events are getting cleaned up somehow
      initEditorListeners();
    }

    // setup action listeners for edit buttons if this is not an hls stream
    return () => {
      if (playerId) {
        elementRef?.plyr.off("loadeddata", updateMarkers);
        elementRef?.plyr.off("error", handleError);
        elementRef?.plyr.off("loadstart", updatePlayerLoader);
        elementRef?.plyr.off("canplay", updatePlayerLoader);
      }

      destroyEditorListeners();
    };
  }, [playerId, cuepoints, trimPoint, addCuepoint, removeNearestCuepoint, trimVideo, changeSource]);

  function getOptionsForPlayer() {
    if (isHls) {
      if (limitedOptions) {
        return hlsPlayerOptionsLimited;
      }
      return hlsPlayerOptions;
    }

    if (limitedOptions) {
      if (changeSource) {
        return editorPlayerOptionsLimitedWithChangeSource;
      }
      return editorPlayerOptionsLimited;
    }

    if (changeSource) {
      return editorPlayerOptionsWithChangeSource;
    }

    return editorPlayerOptions;
  }

  return (
    <div
      className={`plyr-container ${title ? "plyr-container--with-title" : ""} ${editControls ? "" : "plyr-container--hide-controls"} `}
      ref={containerRef}
      style={playerDims}
    >
      {title ? <h4 className="plyr-title">{title.toUpperCase()}</h4> : null}
      <Plyr
        ref={playerRef}
        className={`plyr-react plyr ${trimPoint ? "plyr--is-trimmed" : ""}`}
        source={null}
        id={id}
        autoPlay={true}
        muted={true}
        markers={{ enabled: true, points: [] }}
        options={getOptionsForPlayer()}
      ></Plyr>
      <PlayerLoader isLoading={isLoading} />
    </div>
  );
}

export function AssetPlayer({ assetId, ...props }) {
  const [streamUrl, setStreamUrl] = React.useState();
  React.useEffect(() => {
    if (assetId) {
      getAsset(assetId)
        .then((r) => {
          setStreamUrl(r.source_url);
        })
        .catch((e) => new Error(e));
    }
  }, [assetId]);

  return <Player {...props} source={streamUrl} />;
}

export function HlsPlayer({ source, ...props }) {
  return <Player {...props} source={source} isHls={true} id="hls-stream" />;
}

export function DashPlayer({ source, ...props }) {
  return <Player {...props} source={source} isDash={true} id="dash-stream" />;
}

export function HlsOrDashPlayer({ type, source, ...props }) {
  if (type === "hls") {
    return <HlsPlayer {...props} source={source} />;
  } else if (type === "dash") {
    return <DashPlayer {...props} source={source} />;
  }
}

export default Player;
