import React from "react";
import ContentCard from "./components/content-card.jsx";
import AppBody from "../../layout/parts/app-body.jsx";
import { AppHeading } from "../../layout/parts/app-heading.jsx";
import { useParams } from "react-router-dom";
import useApiRequest from "../../hooks/use-api-request.js";
import ImageWithFallback from "../../components/image-with-fallback.jsx";
import TabbedContent from "../../components/tabbed-content.jsx";
import ArtworkTab from "./tabs/artwork-tab.jsx";
import FilesTab from "./tabs/files-tab.jsx";
import FeatureTab from "./tabs/feature-tab.jsx";

function FeaturePage() {
  const { feature_guid } = useParams();
  const { isLoading, response: summaryResponse } = useApiRequest(`/api/features/${feature_guid}/overview`);

  const breadcrumbs = [
    {
      link: "/content",
      title: "Content",
    },
    {
      link: "/content/library",
      title: "Library",
    },
    {
      title: summaryResponse?.data
        ? `${summaryResponse?.data.formatted_name} ${summaryResponse?.data.active ? "" : "(Inactive)"}`
        : "...",
      link: `/content/library/feature/${feature_guid}`,
    },
  ];

  return (
    <React.Fragment>
      <AppHeading breadcrumbs={breadcrumbs} />
      <AppBody loading={isLoading}>
        {!isLoading && (
          <div className="spread-container">
            <div className="spread-container__middle spread-container__middle--alone">
              <div className="content-page">
                <div className="content-page__media-row">
                  <div className="content-page__media-row__media">
                    <ContentCard noPadding>
                      <ImageWithFallback url={summaryResponse?.data?.poster} />
                    </ContentCard>
                  </div>
                  <div className="content-page__media-row__description">
                    <ContentCard>
                      <h1 className="content-page__title">{summaryResponse?.data?.formatted_name}</h1>
                      <h3 className="content-page__sub-title">Short Summary</h3>
                      <p className="content-page__text">{summaryResponse?.data?.short_summary}</p>
                      <h3 className="content-page__sub-title">Long Summary</h3>
                      <div className="content-page__text">{summaryResponse?.data?.long_summary}</div>
                    </ContentCard>
                  </div>
                </div>
                <div className="content-page__body">
                  <TabbedContent
                    tabs={[
                      {
                        label: "Feature",
                        name: "feature",
                        component: FeatureTab,
                        props: { guid: feature_guid },
                      },
                      {
                        label: "Artwork",
                        name: "art",
                        component: ArtworkTab,
                        props: { guid: feature_guid, type: "features" },
                      },
                      {
                        label: "Files",
                        name: "files",
                        component: FilesTab,
                        props: {
                          type: "features",
                          guid: feature_guid,
                          folders: [
                            {
                              label: "Feature",
                              url: `/api/features/${feature_guid}/files/summary`,
                            },
                          ],
                        },
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </AppBody>
    </React.Fragment>
  );
}

export default FeaturePage;
