import React from "react";
import Loader from "../common/loader.jsx";
import fetcher from "../common/fetcher.js";
import EpgEditor from "./epg-editor/epg-editor.jsx";
import ChannelHeading from "./epg-editor/components/channel-heading.jsx";
import { BASE_URL } from "../requests/api-requests.js";
import dateIsBetween from "../common/date-is-between.js";
import { addMinutes, addSeconds, endOfDay, isAfter, setHours, setMinutes, startOfDay, format } from "date-fns";
import { getTimezoneOffset } from "date-fns-tz";
import { dateToHourMinutes } from "../common/duration-formatting.js";
import axios from "../requests/axios.js";
import { toast } from "react-toastify";
import ErrorBoundary from "../components/error-boundary.jsx";
import programTimeCorrection from "../functions/program-time-correction.js";
import UnsavedChangesDialog from "./epg-editor/components/dialogs/unsaved-changes-dialog.jsx";
import { v4 as uuid } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import SchedulerProvider from "../providers/scheduler-context.jsx";
import useCuepointSpacingValidator from "./epg-editor/hooks/use-cuepoint-spacing-validator.jsx";

function SchedulerPage() {
  const [isFetchingChannel, setFetchingChannel] = React.useState(true);
  const [isFetchingPlan, setFetchingPlan] = React.useState(true);
  const [channel, setChannel] = React.useState(null);
  const [plan, setPlan] = React.useState(null);
  const [lastChanged, setLastChanged] = React.useState(null);
  const [updatedAt, setUpdatedAt] = React.useState();
  const saveToastRef = React.useRef();
  const { channelGuid, planDate } = useParams();
  const [clearPlanToggle, setClearPlanToggle] = React.useState(0);
  const [timezone, setTimezone] = React.useState(window.localStorage.getItem("__gstv_timezone") ?? "UTC");
  const [activePlayback, setActivePlayback] = React.useState({});
  const [isSaving, setSaving] = React.useState(false);
  const [autosaveEnabled, setAutosaveEnabled] = React.useState(false);
  const [isAutosaving, setIsAutosaving] = React.useState(false); // trigger the autosave
  const navigate = useNavigate();

  const layoutRef = React.useRef();

  function resetLayout() {
    layoutRef.current.resetLayout();
  }

  const formatTimeForTimezone = React.useCallback(
    (dateTime) => {
      let time = dateTime;

      if (timezone === "Europe/London") {
        let offsetDate = new Date(dateTime);
        const modifier = offsetDate < 0 ? -1 : 1;
        offsetDate = addMinutes(offsetDate, (getTimezoneOffset("Europe/London", dateTime) / 60000) * modifier);
        time = offsetDate;
      }

      return format(time, "HH:mm:ss");
    },
    [timezone],
  );

  const notifyUpdated = React.useCallback(() => {
    setLastChanged(new Date());
  }, []);

  const { areEpgProgramCuepointsSpaced } = useCuepointSpacingValidator();

  const [year, month, day] = planDate.split("-");
  const referencePlanDate = React.useMemo(() => new Date(planDate), [planDate]);
  referencePlanDate.setFullYear(year, month - 1, day);

  const [changesDialog, setChangesDialog] = React.useState({
    open: false,
  });

  function updateActiveDate(input) {
    navigate(`/scheduler/${channelGuid}/${format(input, "y-MM-dd")}`);
  }

  function toggleAutosaving() {
    setAutosaveEnabled((prev) => !prev);
  }

  function notifyPlanCleared() {
    setClearPlanToggle(clearPlanToggle + 1);
  }

  function resetClearPlanNotifier() {
    setClearPlanToggle(0);
  }

  function clearPlan(plan) {
    setPlan(plan);
    notifyPlanCleared();
  }

  function openChangesDialog(action, message, newDate = null) {
    setChangesDialog({
      open: true,
      action,
      message,
      newDate,
    });
  }
  function closeChangesDialog() {
    setChangesDialog({});
  }

  const scheduleUpdated = React.useCallback(() => {
    return lastChanged.getTime() !== new Date(updatedAt).getTime();
  }, [lastChanged, updatedAt]);

  // fetch the channel
  async function getChannel(id) {
    return await fetcher(`${BASE_URL}/api/channels/${id}`);
  }

  const getPlan = React.useCallback(async (id, planDate) => {
    const plan = await fetcher(`${BASE_URL}/api/channels/${id}/plans?plan_date=${planDate}`);
    if (!plan) {
      return null;
    }

    return plan;
  }, []);

  const verifyPlanDoesNotExceedDay = React.useCallback(
    (epg) => {
      const postInsertDuration = epg.reduce((prev, curr) => {
        return prev + curr.__gstvMeta.total_duration_seconds;
      }, 0);

      const lastProgram = epg[epg.length - 1];
      if (lastProgram && isAfter(lastProgram.till, addSeconds(endOfDay(referencePlanDate), 1))) {
        return false;
      }

      return postInsertDuration <= 86400;
    },
    [referencePlanDate],
  );

  const saveChannelPlan = React.useCallback(() => {
    // this is a really bad idea, but lets go
    if (window.__gstv_get_current_epg) {
      const epg = window.__gstv_get_current_epg();

      if (hasInactivePrograms(epg)) {
        toast.error("You cannot save a plan with inactive programs.");
        return;
      }

      if (validatePrograms(epg, plan.plan_breaks).length) {
        toast.error("You cannot save a plan with programs overlapping breaks.");
        return;
      }

      if (!verifyPlanDoesNotExceedDay(epg)) {
        toast.error("Scheduled items cannot exceed 24 hours");
        return;
      }

      let cuepointsCorrectlySpaced = true;
      epg.forEach((epgProgram) => {
        if (!areEpgProgramCuepointsSpaced(epgProgram, formatTimeForTimezone)) {
          cuepointsCorrectlySpaced = false;
        }
      });
      if (!cuepointsCorrectlySpaced) {
        return;
      }

      setSaving(true);
      const payload = {};
      payload.programs = epg.map((program) => {
        const { program_start, program_end } = programTimeCorrection(plan.plan_date, program.since, program.till);

        return {
          ad_breaks: program.__gstvMeta.ad_breaks,
          duration_seconds: program.__gstvMeta.duration_seconds,
          total_duration_seconds: program.__gstvMeta.total_duration_seconds,
          link_id: program.__gstvMeta.link_guid,
          link_type: program.__gstvMeta.link_type,
          program_id: program.__gstvMeta.program_id,
          program_start: beforeToISOString(program_start),
          program_end: beforeToISOString(program_end),
        };
      });

      payload.plan_breaks = plan.plan_breaks;

      saveToastRef.current = toast.loading("Saving schedule...");
      axios
        .post(`${BASE_URL}/api/channels/${channel.channel_id}/plans/${plan.channel_plan_id}`, payload)
        .then((t) => {
          setUpdatedAt(t.data.updated_at);
          setLastChanged(new Date(t.data.updated_at));
          toast.update(saveToastRef.current, {
            render: "Scheduled saved successfully",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
        })
        .catch((e) => {
          console.error(e);
          let message = "Failed to save schedule";
          if (e?.data?.message) {
            message = e.data.message;
          } else if (e?.data?.errors) {
            message = "" + e.data.errors;
          }
          toast.update(saveToastRef.current, {
            render: message,
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
        })
        .finally(() => setSaving(false));
    }
  }, [
    areEpgProgramCuepointsSpaced,
    channel?.channel_id,
    formatTimeForTimezone,
    plan?.channel_plan_id,
    plan?.plan_breaks,
    plan?.plan_date,
    verifyPlanDoesNotExceedDay,
  ]);

  React.useEffect(() => {
    setFetchingChannel(true);
    getChannel(channelGuid)
      .then((channel) => {
        setChannel(channel);
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setFetchingChannel(false);
      });
  }, [channelGuid]);

  React.useEffect(() => {
    setFetchingPlan(true);

    getPlan(channelGuid, planDate)
      .then((plan) => {
        plan.programs.forEach((planProgram) => {
          planProgram.program_start = fromUtcDate(planProgram.program_start);
          planProgram.program_end = fromUtcDate(planProgram.program_end);
          return planProgram;
        });
        setPlan(plan);
        setUpdatedAt(plan.updated_at);
        setLastChanged(new Date(plan.updated_at));
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setFetchingPlan(false);
      });
  }, [channelGuid, planDate, getPlan]);

  React.useEffect(() => {
    let timer;
    if (lastChanged) {
      timer = setTimeout(() => {
        if (scheduleUpdated()) {
          openChangesDialog(
            "timeoutWithChanges",
            "It's been 10 minutes without saving. Don't forget to save your changes.",
          );
        }
      }, 600000);
    }
    return () => clearTimeout(timer);
  }, [updatedAt, lastChanged, scheduleUpdated]);

  // when channel changes, decide which url to use
  React.useEffect(() => {
    if (channel?.channel_id && window.localStorage.getItem(`__gstv-ui__channel-playback-url__${channel.channel_id}`)) {
      const playbackData = window.localStorage
        .getItem(`__gstv-ui__channel-playback-url__${channel.channel_id}`)
        .split("___");
      setActivePlayback({ url: playbackData[0], type: playbackData[1] });
    } else if (channel?.url) {
      setActivePlayback({ url: channel.url, type: "hls" });
    }
  }, [channel?.channel_id, channel?.url]);

  // when selected playback_url changes
  React.useEffect(() => {
    // save non-null urls to localStorage
    if (channel?.channel_id && activePlayback?.url) {
      window.localStorage.setItem(
        `__gstv-ui__channel-playback-url__${channel.channel_id}`,
        `${activePlayback.url}___${activePlayback.type}`,
      );
    }
  }, [activePlayback?.url, activePlayback?.type, channel?.channel_id]);

  // create Interval for autosaving
  React.useEffect(() => {
    const autosave = setInterval(() => {
      setIsAutosaving(true);
    }, 60 * 1000); // runs once a minute
    return () => {
      setIsAutosaving(false);
      clearInterval(autosave); // cleanup interval
    };
  }, []);

  // using two effects to avoid stale data
  React.useEffect(() => {
    if (autosaveEnabled && isAutosaving) {
      saveChannelPlan();
      setIsAutosaving(false);
    }
  }, [autosaveEnabled, isAutosaving, saveChannelPlan]);

  async function addPlanBreak(startTime) {
    const breaksCopy = Array.from(plan.plan_breaks);
    let referenceDate = new Date(planDate);
    const [year, month, day] = planDate.split("-");
    referenceDate.setFullYear(year, month - 1, day);
    referenceDate = startOfDay(referenceDate);

    const targetBreakIndex = breaksCopy.findIndex((pb) => {
      const [hours, minutes] = pb.start.split(":");
      const [endHours, endMinutes] = pb.end.split(":");
      const start = setHours(setMinutes(referenceDate, minutes), hours);
      const end = setHours(setMinutes(referenceDate, endMinutes), endHours);
      return dateIsBetween(startTime, start, end);
    });

    const end = breaksCopy[targetBreakIndex].end;
    const start = dateToHourMinutes(startTime);
    const newBreak = {
      start,
      end,
      type: "plan",
    };
    breaksCopy[targetBreakIndex].end = start;
    breaksCopy.splice(targetBreakIndex + 1, 0, newBreak);

    setPlan((plan) => ({
      ...plan,
      plan_breaks: breaksCopy,
    }));
  }

  async function deletePlanBreak(start) {
    const planBreaks = [...plan.plan_breaks];
    const indexToRemove = planBreaks.findIndex((pb) => pb.start === start);
    planBreaks[indexToRemove - 1].end = planBreaks[indexToRemove].end;
    planBreaks.splice(indexToRemove, 1);

    setPlan((plan) => ({
      ...plan,
      plan_breaks: planBreaks,
    }));
  }

  function hasInactivePrograms(plan) {
    return !plan.every((program) => !!program.isActive);
  }

  function validatePrograms(epg, planBreaks) {
    return epg.filter((program) =>
      planBreaks.some((pBreak) => {
        const result = dateIsBetween(
          setHours(setMinutes(program.since, pBreak.end.split(":")[1]), pBreak.end.split(":")[0]),
          program.since,
          program.till,
          "()",
        );
        return result;
      }),
    );
  }

  // dates are visually in the users timezone, but in reality they are UTC
  // this resolves the call that axios will make to do .toISOString() to keep things UTC-like
  function beforeToISOString(dateInTz) {
    let offsetDate = new Date(dateInTz);
    const modifier = offsetDate < 0 ? 1 : -1;
    offsetDate = addMinutes(offsetDate, offsetDate.getTimezoneOffset() * modifier);
    return offsetDate;
  }

  function fromUtcDate(utcDate) {
    let offsetDate = new Date(utcDate);
    const modifier = offsetDate < 0 ? -1 : 1;
    offsetDate = addMinutes(offsetDate, offsetDate.getTimezoneOffset() * modifier);
    return offsetDate;
  }

  function toggleTimezone() {
    const nextTimezone = timezone === "UTC" ? "Europe/London" : "UTC";

    setTimezone(nextTimezone);
    window.localStorage.setItem("__gstv_timezone", nextTimezone);
  }

  function handleError() {
    console.error("Irrecoverable error");
    window.location.reload();
  }

  return (
    <SchedulerProvider timezone={timezone}>
      <ErrorBoundary onError={handleError}>
        {(hasError, resolveError) =>
          isFetchingChannel || !plan ? (
            <div className="scheduler-loader">
              <Loader />
            </div>
          ) : (
            <div className="spread-container">
              <div className="spread-container__top">
                <ChannelHeading
                  channel={channel}
                  planDate={referencePlanDate}
                  changePlanDate={updateActiveDate}
                  clearPlan={clearPlan}
                  saveChannelPlan={saveChannelPlan}
                  planId={plan?.channel_plan_id}
                  planUpdated={updatedAt}
                  scheduleUpdated={scheduleUpdated}
                  openChangesDialog={openChangesDialog}
                  toggleTimezone={toggleTimezone}
                  playoutUrl={activePlayback}
                  setPlayoutUrl={setActivePlayback}
                  isSaving={isSaving}
                  autosaveEnabled={autosaveEnabled}
                  toggleAutosave={toggleAutosaving}
                  resetLayout={resetLayout}
                />
              </div>
              <div className="spread-container__middle">
                {!isFetchingPlan ? (
                  <EpgEditor
                    channel={channel}
                    plan={plan}
                    activeDate={referencePlanDate}
                    addPlanBreak={addPlanBreak}
                    deletePlanBreak={deletePlanBreak}
                    hasError={hasError}
                    resolveError={resolveError}
                    notifyUpdated={notifyUpdated}
                    clearPlanNotifier={clearPlanToggle}
                    resetClearNotifier={resetClearPlanNotifier}
                    playbackData={activePlayback}
                    ref={layoutRef}
                  />
                ) : (
                  <Loader />
                )}
              </div>
              <UnsavedChangesDialog
                newDate={changesDialog.newDate}
                isOpen={changesDialog.open}
                onClose={closeChangesDialog}
                updatePlanDate={updateActiveDate}
                message={changesDialog.message}
                action={changesDialog.action}
                key={`unsaved_changes_dialog-${uuid()}`}
              />
            </div>
          )
        }
      </ErrorBoundary>
    </SchedulerProvider>
  );
}

export default SchedulerPage;
