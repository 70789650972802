import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { secondsToHourMinutesSeconds } from "../../../common/duration-formatting.js";
import format from "date-fns/format";
import differenceInSeconds from "date-fns/differenceInSeconds";
import useSegments from "../../../components/vertical-scheduler/hooks/useSegments.jsx";

const SECONDS_IN_A_NORMAL_DAY = 86400;

function PlannerMeta({ epg, planBreaks, planDate }) {
  const segments = useSegments(planDate, planBreaks, epg);
  let scheduledSeconds = 0;
  let adBreakDurationSeconds = 0;
  let actualSeconds = 0;
  for (let i = 0; epg.length > i; i++) {
    scheduledSeconds += epg[i].__gstvMeta.total_duration_seconds;
    actualSeconds += epg[i].__gstvMeta.duration_seconds;
    adBreakDurationSeconds += epg[i].__gstvMeta.total_duration_seconds - epg[i].__gstvMeta.duration_seconds;
  }

  const label = secondsToHourMinutesSeconds(SECONDS_IN_A_NORMAL_DAY - scheduledSeconds);

  function getAdLoad() {
    const adLoadInSeconds = (adBreakDurationSeconds / actualSeconds) * 600;
    if (adLoadInSeconds) {
      return secondsToHourMinutesSeconds(adLoadInSeconds);
    }
    return secondsToHourMinutesSeconds(0);
  }

  function getSegmentAdLoad(segment) {
    let segmentActualSeconds = 0;
    let segmentAdBreakDurationSeconds = 0;
    const items = segment.items ?? [];
    for (let i = 0; items.length > i; i++) {
      segmentActualSeconds += items[i].__gstvMeta.duration_seconds;
      segmentAdBreakDurationSeconds +=
        items[i].__gstvMeta.total_duration_seconds - items[i].__gstvMeta.duration_seconds;
    }

    const adLoadInSeconds = (segmentAdBreakDurationSeconds / segmentActualSeconds) * 600;
    if (adLoadInSeconds) {
      return secondsToHourMinutesSeconds(adLoadInSeconds);
    }
    return secondsToHourMinutesSeconds(0);
  }

  function diffBetweenSegmentLengthAndItsItems(segment) {
    return (
      differenceInSeconds(segment.end, segment.start) -
      differenceInSeconds(segment.items[segment.items.length - 1].till, segment.items[0].since)
    );
  }

  function programsExceedSegmentLength(segment) {
    return diffBetweenSegmentLengthAndItsItems(segment) < 0 ? true : false;
  }

  return (
    <div className="planner-meta">
      <div className="planner-meta__progress">
        <ProgressBar
          completed={scheduledSeconds}
          maxCompleted={SECONDS_IN_A_NORMAL_DAY}
          labelAlignment={"outside"}
          customLabel={label}
          bgColor={scheduledSeconds > SECONDS_IN_A_NORMAL_DAY ? "#b94a48" : "#5d14d3"}
          labelColor={"#666"}
        />
      </div>
      <div className="planner-meta__group">
        <div className="planner-meta__fields">
          <div className="program-field">
            <div className="program-field__label">Total Scheduled Time</div>
            <div className="program-field__value">{secondsToHourMinutesSeconds(scheduledSeconds)}</div>
          </div>
          <div className="program-field">
            <div className="program-field__label">Total Ad Time</div>
            <div className="program-field__value">{secondsToHourMinutesSeconds(adBreakDurationSeconds)}</div>
          </div>
          <div className="program-field">
            <div className="program-field__label">Ad Load (10 minutes)</div>
            <div className="program-field__value">{getAdLoad()}</div>
          </div>
        </div>
      </div>
      {segments.map((planBreak, index) => (
        <div key={index} className="planner-meta__group">
          <div className="planner-meta__group__title">
            {`${format(planBreak.start, "HH:mm")} to ${format(planBreak.end, "HH:mm")}`}
          </div>
          <div className="planner-meta__fields">
            <div className="program-field">
              <div className="program-field__label">Total Scheduled Time</div>
              <div className="program-field__value">
                {planBreak.items?.length
                  ? secondsToHourMinutesSeconds(
                      differenceInSeconds(planBreak.items[planBreak.items.length - 1].till, planBreak.items[0].since),
                    )
                  : "-"}
              </div>
            </div>
            <div className="program-field">
              <div className="program-field__label">Time Remaining</div>
              <div
                className={`program-field__value ${
                  planBreak.items?.length && programsExceedSegmentLength(planBreak)
                    ? "program-field__value--warning"
                    : ""
                }`}
              >
                {planBreak.items?.length
                  ? secondsToHourMinutesSeconds(diffBetweenSegmentLengthAndItsItems(planBreak))
                  : "-"}
              </div>
            </div>
            <div className="program-field">
              <div className="program-field__label">Ad Load (10 minutes)</div>
              <div className="program-field__value">{getSegmentAdLoad(planBreak)}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default PlannerMeta;
