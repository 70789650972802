import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import ContentList from "../../components/content-list/content-list.jsx";
import ContentListFilters from "../../components/content-list/content-list-filters.jsx";
import ContentListResults from "../../components/content-list/content-list-results.jsx";
import { getKeyForUnknownContent, getTypeForContent } from "../epg-editor/utils/accessors.js";
import SeriesItem from "../../components/content-list/series-item.jsx";
import ContentListPagination from "../../components/content-list/content-list-pagination.jsx";
import { AssetPlayer } from "../../components/player/player.jsx";
import useContentList from "../../components/content-list/use-content-list.js";
import DraggableAvodItem from "./draggable-avod-item.jsx";

function contentProgramToAvodProgram(contentProgram) {
  const copy = JSON.parse(JSON.stringify(contentProgram));
  return {
    link_id: contentProgram[getKeyForUnknownContent(contentProgram, "id")],
    link_guid: contentProgram[getKeyForUnknownContent(contentProgram, "guid")],
    link_type: getTypeForContent(contentProgram),
    link: copy,
  };
}

export default function AvodPlaylist({ playlist, providers, updatePrograms, editingDisabled = false }) {
  const { programs, isLoading: contentListLoading, getPrograms } = useContentList({ planDate: null });
  const [series, setSeries] = React.useState({});
  const [streamAssetId, setStreamAssetId] = React.useState("");
  const [pageSize, setPageSize] = React.useState(50); // 50 items per page by default

  const resetSeries = React.useCallback(() => {
    setSeries({});
  }, []);

  function onDragEnd({ destination, source }) {
    if (source.droppableId === "content-list") {
      // no actions on invalid drop from content list drag
      if (!destination || !destination.droppableId) {
        return;
      }

      // no actions on drag+drop of content list
      if (destination.droppableId === source.droppableId) {
        return;
      }
    }

    let nextList = null;

    if (source.droppableId === "playlist") {
      if (!destination || !destination.droppableId) {
        // remove
        nextList = [...playlist.slice(0, source.index), ...playlist.slice(source.index + 1)];
      } else if (destination.droppableId === source.droppableId) {
        // reorder
        const item = playlist[source.index];
        nextList = [...playlist.slice(0, source.index), ...playlist.slice(source.index + 1)];
        nextList = [...nextList.slice(0, destination.index), item, ...nextList.slice(destination.index)];
      }
    } else if (source.droppableId === "content-list") {
      if (destination.droppableId === "playlist") {
        // move
        const target = contentProgramToAvodProgram(programs.data[source.index]);
        nextList = [...playlist.slice(0, destination.index), target, ...playlist.slice(destination.index)];
      }
    }

    if (nextList !== null) {
      updatePrograms(nextList);
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="gw gw--full-height">
        <div className="g g--1-of-2 g--desk-wide-1-of-3 g--full-height">
          <ContentList>
            <ContentListFilters
              providers={providers}
              getPrograms={getPrograms}
              overridePagesize={pageSize}
              showInactiveContent={false}
              series={series}
              resetSeries={resetSeries}
            />
            <ContentListResults isLoading={contentListLoading} results={programs?.data}>
              {(results) => (
                <Droppable droppableId="content-list" direction="vertical" isDropDisabled={true}>
                  {(provided) => (
                    <ul {...provided.droppableProps} ref={provided.innerRef} className="content-list__list">
                      {getTypeForContent(results[0]) === "series"
                        ? results.map((item, index) => (
                            <li
                              className="content-list__item content-list__item--clickable"
                              onClick={() => setSeries({ value: item.series_guid, label: item.series_name })}
                              key={`content-list__item__${index}`}
                            >
                              <SeriesItem series={item} getEpisodesForSeries={setSeries} actions={[]} />
                            </li>
                          ))
                        : results.map((item, index) => (
                            <DraggableAvodItem
                              program={item}
                              index={index}
                              key={item[getKeyForUnknownContent(item, "guid")]}
                              draggableId={item[getKeyForUnknownContent(item, "guid")]}
                              addProgram={() =>
                                onDragEnd({
                                  source: {
                                    index: index,
                                    droppableId: "content-list",
                                  },
                                  destination: {
                                    index: playlist.length,
                                    droppableId: "playlist",
                                  },
                                })
                              }
                              selectProgram={() => {}}
                              onPlay={setStreamAssetId}
                              onActions={{
                                getEpisodesForSeries: setSeries,
                              }}
                              editingDisabled={editingDisabled}
                            />
                          ))}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              )}
            </ContentListResults>
            <ContentListPagination
              getPrograms={getPrograms}
              prevUrl={programs?.prev_page_url}
              nextUrl={programs?.next_page_url}
              size={pageSize}
              setSize={setPageSize}
            />
          </ContentList>
        </div>
        <div className="g g--1-of-2 g--desk-wide-2-of-3 g--full-height">
          <div className="u-flex-columns">
            <div className="avod-playlist__content__video">
              <div className="video-player">
                <AssetPlayer assetId={streamAssetId} title={"Preview"} editControls={false} />
              </div>
            </div>
            <div className="height-container">
              <Droppable droppableId="playlist" direction="vertical" isDropDisabled={false}>
                {(provided) => (
                  <ul
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={`content-list__list ${!playlist.length ? "content-list__list--empty" : ""}`}
                  >
                    {playlist.map((program, index) => (
                      <DraggableAvodItem
                        program={program.link}
                        index={index}
                        key={`playlist-${index}-${program[getKeyForUnknownContent(program, "guid")]}`}
                        draggableId={`playlist-${index}-${program[getKeyForUnknownContent(program, "guid")]}`}
                        removeProgram={() =>
                          onDragEnd({
                            source: {
                              index: index,
                              droppableId: "playlist",
                            },
                          })
                        }
                        selectProgram={() => {}}
                        onPlay={setStreamAssetId}
                        onActions={{
                          getEpisodesForSeries: setSeries,
                        }}
                        editingDisabled={editingDisabled}
                      />
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </div>
          </div>
        </div>
      </div>
    </DragDropContext>
  );
}
