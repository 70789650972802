import React, { createContext } from "react";
import { login } from "../requests/api-requests.js";
import jwt_decode from "jwt-decode";
import getToken from "../functions/getToken.js";

function getUserFromStorage() {
  const token = getToken(false);
  try {
    return jwt_decode(token);
  } catch {
    return null;
  }
}

function useProvideAuth() {
  const [user, setUser] = React.useState(getUserFromStorage);
  const isAuthenticated = () => getUserFromStorage() !== null;

  const attemptLogin = (email, password) => {
    return new Promise((resolve, reject) => {
      login({ email, password })
        .then((response) => {
          const token = response.headers?.authorization || response.headers?.Authorization;
          window.localStorage.setItem("__bearer", token);

          setUser(getUserFromStorage());
          resolve(response);
        })
        .catch(reject);
    });
  };

  const logout = (cb) => {
    window.localStorage.clear();
    setUser(null);
    if (cb) {
      cb();
    }
  };

  return {
    user,
    logout,
    attemptLogin,
    isAuthenticated,
  };
}

const authContext = createContext({
  user: null,
  logout: () => null,
  attemptLogin: () => null,
  isAuthenticated: () => false,
});

export function useAuth() {
  return React.useContext(authContext);
}
export default function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
